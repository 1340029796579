export const srt1 = {
    with_speaker: `0
    00:00:00,760 --> 00:00:01,278
    okay that's [CHILD]
    
    2
    00:00:03,280 --> 00:00:03,934
    banana no [CHILD]
    
    4
    00:00:05,320 --> 00:00:06,334
    try again that's [THERAPIST]
    
    8
    00:00:07,860 --> 00:00:08,542
    banana very [CHILD]
    
    10
    00:00:09,480 --> 00:00:11,518
    nice okay [THERAPIST]
    
    12
    00:00:12,226 --> 00:00:14,526
    that's banana good [THERAPIST]
    
    15
    00:00:17,000 --> 00:00:18,718
    job that's [THERAPIST]
    
    17
    00:00:19,700 --> 00:00:20,254
    banana that's [CHILD]
    
    19
    00:00:21,500 --> 00:00:22,100
    right banana [CHILD]
    
    21
    00:00:27,650 --> 00:00:28,382
    that's banana very [CHILD]
    
    24
    00:00:30,500 --> 00:00:32,286
    good okay [THERAPIST]
    
    26
    00:00:34,946 --> 00:00:35,838
    that's [THERAPIST]
    
    27
    00:00:38,680 --> 00:00:38,920
    banana [THERAPIST]
    
    28
    00:00:41,506 --> 00:00:42,340
    excellent job [CHILD]
    
    `,
    without_speaker: `0
    00:00:00,760 --> 00:00:01,278
    okay that's
    
    2
    00:00:03,280 --> 00:00:03,934
    banana no
    
    4
    00:00:05,320 --> 00:00:06,334
    try again that's
    
    8
    00:00:07,860 --> 00:00:08,542
    banana very
    
    10
    00:00:09,480 --> 00:00:11,518
    nice okay
    
    12
    00:00:12,226 --> 00:00:14,526
    that's banana good
    
    15
    00:00:17,000 --> 00:00:18,718
    job that's
    
    17
    00:00:19,700 --> 00:00:20,254
    banana that's
    
    19
    00:00:21,500 --> 00:00:22,100
    right banana
    
    21
    00:00:27,650 --> 00:00:28,382
    that's banana very
    
    24
    00:00:30,500 --> 00:00:32,286
    good okay
    
    26
    00:00:34,946 --> 00:00:35,838
    that's
    
    27
    00:00:38,680 --> 00:00:38,920
    banana
    
    28
    00:00:41,506 --> 00:00:42,340
    excellent job
    
    `,
}
export const srt2 = {
    with_speaker: `0
    00:00:00,098 --> 00:00:00,720
    Up the top here. [THERAPIST]
    
    4
    00:00:00,860 --> 00:00:01,740
    Up the top. [THERAPIST]
    
    7
    00:00:02,040 --> 00:00:02,540
    Beautiful! [THERAPIST]
    
    8
    00:00:03,160 --> 00:00:04,580
    Give me that hand. [THERAPIST]
    
    12
    00:00:04,840 --> 00:00:05,640
    Here we go. [CHILD]
    
    15
    00:00:06,080 --> 00:00:11,540
    Round and round the garden like a teddy bear. [THERAPIST]
    
    27
    00:00:12,460 --> 00:00:14,280
    One step. [THERAPIST]
    
    29
    00:00:14,540 --> 00:00:16,620
    Brrr! Two step. [THERAPIST]
    
    33
    00:00:16,980 --> 00:00:21,000
    Brrr! Tickle Daniel everywhere. [THERAPIST]
    
    39
    00:00:21,480 --> 00:00:23,540
    You are such a ticklish guy. I see a teddy. [THERAPIST]
    
    47
    00:00:23,960 --> 00:00:24,420
    What's that? [THERAPIST]
    
    49
    00:00:24,800 --> 00:00:25,200
    Teddy. [THERAPIST]
    
    50
    00:00:25,680 --> 00:00:27,300
    Oh, do you see your teddy? [THERAPIST]
    
    57
    00:00:27,700 --> 00:00:27,820
    Yeah. [THERAPIST]
    
    58
    00:00:28,000 --> 00:00:29,180
    Oh, you do too. [THERAPIST]
    
    63
    00:00:29,260 --> 00:00:30,940
    Let's get him and we can have a quick kiss. [THERAPIST]
    
    73
    00:00:31,100 --> 00:00:31,760
    Come on, Teddy. [CHILD]
    
    77
    00:00:33,596 --> 00:00:34,046
    Okay, [THERAPIST]
    
    78
    00:00:36,620 --> 00:00:37,860
    what we're going to do with Teddy now, [THERAPIST]
    
    85
    00:00:37,960 --> 00:00:39,440
    let's put him up here so he can watch [THERAPIST]
    
    94
    00:00:39,550 --> 00:00:42,760
    because it's time to play the popcorn game. [THERAPIST]
    
    102
    00:00:43,060 --> 00:00:47,260
    Okay, but uh-oh, we don't have all the pieces. [THERAPIST]
    
    114
    00:00:47,700 --> 00:00:52,220
    So I need you to tell me what we need to get so we can play. [THERAPIST]
    
    130
    00:00:52,280 --> 00:00:53,920
    Nice sitting, Daniel. [THERAPIST]
    
    134
    00:00:53,980 --> 00:00:54,640
    Very good. [THERAPIST]
    
    136
    00:00:55,000 --> 00:00:56,020
    What do we need to get? [THERAPIST]
    
    142
    00:00:56,260 --> 00:00:57,040
    The lid. [THERAPIST]
    
    144
    00:00:57,420 --> 00:00:58,140
    What's that? [THERAPIST]
    
    146
    00:00:58,460 --> 00:00:58,900
    Lid. [THERAPIST]
    
    147
    00:00:59,120 --> 00:01:00,020
    Get the lid. [CHILD]
    
    150
    00:01:00,260 --> 00:01:00,640
    Okay. [THERAPIST]
    
    151
    00:01:00,760 --> 00:01:02,540
    I like your sitting. [THERAPIST]
    
    155
    00:01:02,660 --> 00:01:03,320
    Here we go. [THERAPIST]
    
    158
    00:01:04,660 --> 00:01:04,760
    The [THERAPIST]
    
    159
    00:01:05,826 --> 00:01:06,820
    big lid. [THERAPIST]
    
    161
    00:01:07,140 --> 00:01:08,460
    Oh, the big lid. [THERAPIST]
    
    166
    00:01:08,640 --> 00:01:09,260
    Silly me. [THERAPIST]
    
    168
    00:01:09,460 --> 00:01:10,300
    Of course. [CHILD]
    
    170
    00:01:10,420 --> 00:01:12,100
    Here's the big lid. [THERAPIST]
    
    174
    00:01:12,380 --> 00:01:13,760
    Great thinking, Daniel. [THERAPIST]
    
    178
    00:01:13,960 --> 00:01:15,380
    What else do we need? [THERAPIST]
    
    183
    00:01:15,800 --> 00:01:17,440
    Popcorn. [THERAPIST]
    
    185
    00:01:17,500 --> 00:01:18,600
    Okay, you stay in your chair. [CHILD]
    
    192
    00:01:18,800 --> 00:01:19,940
    I'll get the popcorn. [THERAPIST]
    
    196
    00:01:20,240 --> 00:01:21,520
    I've got the popcorn. [THERAPIST]
    
    200
    00:01:25,000 --> 00:01:25,280
    Again. [CHILD]
    
    201
    00:01:25,280 --> 00:01:26,600
    Okay, here we go. [CHILD]
    
    206
    00:01:26,880 --> 00:01:29,980
    Nice staying in your chair, [THERAPIST]
    
    211
    00:01:30,080 --> 00:01:30,220
    buddy. [THERAPIST]
    
    212
    00:01:30,340 --> 00:01:31,300
    What else do we need? [THERAPIST]
    
    217
    00:01:31,780 --> 00:01:32,660
    Oh, it's on. [THERAPIST]
    
    221
    00:01:32,920 --> 00:01:33,854
    A [THERAPIST]
    
    222
    00:01:35,040 --> 00:01:35,140
    cup. [THERAPIST]
    
    223
    00:01:35,340 --> 00:01:36,760
    We need a cup. [THERAPIST]
    
    228
    00:01:37,080 --> 00:01:37,460
    Okay. [THERAPIST]
    
    229
    00:01:37,560 --> 00:01:38,640
    This one? [THERAPIST]
    
    231
    00:01:39,560 --> 00:01:39,820
    Yeah. [THERAPIST]
    
    232
    00:01:40,080 --> 00:01:40,760
    You want that one? [CHILD]
    
    236
    00:01:40,880 --> 00:01:41,700
    Okay, there you go. [CHILD]
    
    241
    00:01:41,900 --> 00:01:43,800
    And I'll get the red cup. [THERAPIST]
    
    247
    00:01:43,980 --> 00:01:44,740
    Here we go. [THERAPIST]
    
    250
    00:01:44,860 --> 00:01:46,280
    Let's turn it on. [THERAPIST]
    
    254
    00:01:46,860 --> 00:01:48,254
    All [THERAPIST]
    
    255
    00:01:49,820 --> 00:01:50,500
    right, let's get him. [THERAPIST]
    
    260
    00:01:50,800 --> 00:01:52,020
    Oh, got one. [THERAPIST]
    
    264
    00:01:55,000 --> 00:01:55,180
    Your turn. [THERAPIST]
    
    266
    00:01:55,480 --> 00:01:56,480
    Oh, can you get it? [THERAPIST]
    
    272
    00:01:56,600 --> 00:01:57,780
    Good try. [THERAPIST]
    
    275
    00:01:58,280 --> 00:01:59,518
    All [THERAPIST]
    
    276
    00:02:00,700 --> 00:02:01,300
    right. [CHILD]
    
    `,
    without_speaker: `0
    00:00:00,098 --> 00:00:00,720
    Up the top here.
    
    4
    00:00:00,860 --> 00:00:01,740
    Up the top.
    
    7
    00:00:02,040 --> 00:00:02,540
    Beautiful!
    
    8
    00:00:03,160 --> 00:00:04,580
    Give me that hand.
    
    12
    00:00:04,840 --> 00:00:05,640
    Here we go.
    
    15
    00:00:06,080 --> 00:00:11,540
    Round and round the garden like a teddy bear.
    
    27
    00:00:12,460 --> 00:00:14,280
    One step.
    
    29
    00:00:14,540 --> 00:00:16,620
    Brrr! Two step.
    
    33
    00:00:16,980 --> 00:00:21,000
    Brrr! Tickle Daniel everywhere.
    
    39
    00:00:21,480 --> 00:00:23,540
    You are such a ticklish guy. I see a teddy.
    
    47
    00:00:23,960 --> 00:00:24,420
    What's that?
    
    49
    00:00:24,800 --> 00:00:25,200
    Teddy.
    
    50
    00:00:25,680 --> 00:00:27,300
    Oh, do you see your teddy?
    
    57
    00:00:27,700 --> 00:00:27,820
    Yeah.
    
    58
    00:00:28,000 --> 00:00:29,180
    Oh, you do too.
    
    63
    00:00:29,260 --> 00:00:30,940
    Let's get him and we can have a quick kiss.
    
    73
    00:00:31,100 --> 00:00:31,760
    Come on, Teddy.
    
    77
    00:00:33,596 --> 00:00:34,046
    Okay,
    
    78
    00:00:36,620 --> 00:00:37,860
    what we're going to do with Teddy now,
    
    85
    00:00:37,960 --> 00:00:39,440
    let's put him up here so he can watch
    
    94
    00:00:39,550 --> 00:00:42,760
    because it's time to play the popcorn game.
    
    102
    00:00:43,060 --> 00:00:47,260
    Okay, but uh-oh, we don't have all the pieces.
    
    114
    00:00:47,700 --> 00:00:52,220
    So I need you to tell me what we need to get so we can play.
    
    130
    00:00:52,280 --> 00:00:53,920
    Nice sitting, Daniel.
    
    134
    00:00:53,980 --> 00:00:54,640
    Very good.
    
    136
    00:00:55,000 --> 00:00:56,020
    What do we need to get?
    
    142
    00:00:56,260 --> 00:00:57,040
    The lid.
    
    144
    00:00:57,420 --> 00:00:58,140
    What's that?
    
    146
    00:00:58,460 --> 00:00:58,900
    Lid.
    
    147
    00:00:59,120 --> 00:01:00,020
    Get the lid.
    
    150
    00:01:00,260 --> 00:01:00,640
    Okay.
    
    151
    00:01:00,760 --> 00:01:02,540
    I like your sitting.
    
    155
    00:01:02,660 --> 00:01:03,320
    Here we go.
    
    158
    00:01:04,660 --> 00:01:04,760
    The
    
    159
    00:01:05,826 --> 00:01:06,820
    big lid.
    
    161
    00:01:07,140 --> 00:01:08,460
    Oh, the big lid.
    
    166
    00:01:08,640 --> 00:01:09,260
    Silly me.
    
    168
    00:01:09,460 --> 00:01:10,300
    Of course.
    
    170
    00:01:10,420 --> 00:01:12,100
    Here's the big lid.
    
    174
    00:01:12,380 --> 00:01:13,760
    Great thinking, Daniel.
    
    178
    00:01:13,960 --> 00:01:15,380
    What else do we need?
    
    183
    00:01:15,800 --> 00:01:17,440
    Popcorn.
    
    185
    00:01:17,500 --> 00:01:18,600
    Okay, you stay in your chair.
    
    192
    00:01:18,800 --> 00:01:19,940
    I'll get the popcorn.
    
    196
    00:01:20,240 --> 00:01:21,520
    I've got the popcorn.
    
    200
    00:01:25,050 --> 00:01:25,600
    I'm going to get the popcorn.
    
    201
    00:01:25,600 --> 00:01:26,600
    Okay, here we go.
    
    204
    00:01:26,860 --> 00:01:29,980
    Nice staying in your chair,
    
    209
    00:01:30,080 --> 00:01:30,220
    buddy.
    
    210
    00:01:30,360 --> 00:01:31,300
    What else do we need?
    
    215
    00:01:31,660 --> 00:01:32,660
    Oh, it's on.
    
    219
    00:01:33,140 --> 00:01:33,854
    A
    
    220
    00:01:35,042 --> 00:01:35,160
    cup.
    
    221
    00:01:35,300 --> 00:01:36,780
    We need a cup.
    
    226
    00:01:37,080 --> 00:01:37,480
    Okay.
    
    227
    00:01:37,560 --> 00:01:38,660
    This one?
    
    229
    00:01:39,480 --> 00:01:39,840
    Yeah.
    
    230
    00:01:40,020 --> 00:01:40,760
    You want that one?
    
    234
    00:01:40,880 --> 00:01:41,700
    Okay, there you go.
    
    239
    00:01:41,900 --> 00:01:43,800
    And I'll get the red cup.
    
    245
    00:01:43,980 --> 00:01:44,760
    Here we go.
    
    248
    00:01:44,860 --> 00:01:46,320
    Let's turn it on.
    
    252
    00:01:46,840 --> 00:01:48,254
    All
    
    253
    00:01:49,780 --> 00:01:50,520
    right, let's get him.
    
    258
    00:01:50,720 --> 00:01:52,020
    Oh, got one.
    
    262
    00:01:52,400 --> 00:01:54,460
    Oh, got two.
    
    266
    00:01:55,000 --> 00:01:55,180
    Your turn.
    
    268
    00:01:55,480 --> 00:01:56,480
    Oh, can you get it?
    
    274
    00:01:56,600 --> 00:01:57,780
    Good try.
    
    277
    00:01:58,280 --> 00:01:59,518
    All
    
    278
    00:02:00,700 --> 00:02:01,300
    right.
    
    `,
}
export const srt3 = {
    with_speaker: `0
    00:00:05,218 --> 00:00:22,020
    oh you're hurting Daddy [THERAPIST]
    
    4
    00:00:52,002 --> 00:00:53,534
    AHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH [THERAPIST]
    
    5
    00:01:00,000 --> 00:01:00,958
    遊 [THERAPIST]
    
    6
    00:01:01,460 --> 00:01:11,620
    disney Mm ghh ghh ghh it Burr it burr It begins now. [THERAPIST]
    
    `,
    without_speaker: `0
    00:00:00,100 --> 00:00:04,414
    AHHHHHHH AHHHHHHHH Hi
    
    5
    00:00:05,180 --> 00:00:07,200
    Really, hi AHHHHHHHH
    
    10
    00:00:07,520 --> 00:00:10,600
    AHHHHHHHH AHHHHHHHH
    
    13
    00:00:11,220 --> 00:00:16,460
    AHHHHHHHH AHHHHHHHH AHHHHHHHH AHHHHHHHH
    
    19
    00:00:17,160 --> 00:00:23,580
    You're hurting Daddy Hi, hi, hi
    
    26
    00:00:23,900 --> 00:00:24,920
    AHHHHHHHH
    
    27
    00:00:31,940 --> 00:00:34,334
    Hi, small AHHHHHHHH
    
    30
    00:00:37,922 --> 00:00:38,494
    AHHH AHHHHHHHH
    
    32
    00:00:39,720 --> 00:00:44,542
    HHHHHH Stop AHHHHHHHHHHHH
    
    36
    00:00:46,274 --> 00:00:47,774
    AHHHHHHHH Billy,
    
    38
    00:00:49,020 --> 00:00:53,534
    Billy AHHH Billy Hi Hi
    
    44
    00:00:54,114 --> 00:00:54,240
    Hi
    
    45
    00:00:55,600 --> 00:00:56,734
    Come on How much is that?
    
    52
    00:00:57,180 --> 00:00:59,040
    One more.
    
    `,
}
export const srt4 = {
    with_speaker: `0
    00:00:01,506 --> 00:00:01,840
    What color? [THERAPIST]
    
    2
    00:00:02,740 --> 00:00:02,840
    Green. [THERAPIST]
    
    3
    00:00:05,860 --> 00:00:07,360
    Do this. [THERAPIST]
    
    8
    00:00:09,760 --> 00:00:10,240
    What color? [THERAPIST]
    
    10
    00:00:10,540 --> 00:00:10,860
    Yellow. [THERAPIST]
    
    11
    00:00:11,360 --> 00:00:12,260
    What color? [CHILD]
    
    13
    00:00:13,058 --> 00:00:13,980
    Yellow. [THERAPIST]
    
    15
    00:00:14,520 --> 00:00:15,420
    Thanks for saying yellow. [THERAPIST]
    
    19
    00:00:30,840 --> 00:00:30,940
    Ready? [THERAPIST]
    
    20
    00:00:31,080 --> 00:00:31,806
    What [CHILD]
    
    21
    00:00:33,474 --> 00:00:33,880
    color? [THERAPIST]
    
    22
    00:00:35,106 --> 00:00:35,280
    Red. [THERAPIST]
    
    23
    00:00:35,480 --> 00:00:35,710
    What [THERAPIST]
    
    24
    00:00:36,980 --> 00:00:37,280
    color? [THERAPIST]
    
    25
    00:00:37,640 --> 00:00:38,340
    Black. [THERAPIST]
    
    26
    00:00:38,680 --> 00:00:38,846
    Good [THERAPIST]
    
    27
    00:00:41,160 --> 00:00:41,840
    work, buddy. [THERAPIST]
    
    30
    00:00:42,160 --> 00:00:43,140
    Nice job. [CHILD]
    
    32
    00:01:10,370 --> 00:01:11,500
    What color? [THERAPIST]
    
    35
    00:01:11,840 --> 00:01:12,222
    Blue. [THERAPIST]
    
    36
    00:01:12,940 --> 00:01:13,406
    What [CHILD]
    
    37
    00:01:14,018 --> 00:01:14,240
    color? [THERAPIST]
    
    38
    00:01:14,920 --> 00:01:14,980
    Red. [THERAPIST]
    
    39
    00:01:16,460 --> 00:01:16,840
    What color? [THERAPIST]
    
    41
    00:01:17,160 --> 00:01:17,400
    Blue. [THERAPIST]
    
    42
    00:01:17,720 --> 00:01:18,270
    What [CHILD]
    
    43
    00:01:18,978 --> 00:01:19,080
    color? [THERAPIST]
    
    44
    00:01:19,440 --> 00:01:19,940
    Black. [THERAPIST]
    
    45
    00:01:21,260 --> 00:01:21,600
    What color? [THERAPIST]
    
    47
    00:01:21,680 --> 00:01:22,880
    Yellow. [THERAPIST]
    
    49
    00:01:23,120 --> 00:01:23,720
    What color? [THERAPIST]
    
    51
    00:01:23,960 --> 00:01:24,380
    Yellow. [THERAPIST]
    
    52
    00:01:24,640 --> 00:01:25,540
    Good work. [THERAPIST]
    
    54
    00:01:41,760 --> 00:01:42,360
    What are you doing? [CHILD]
    
    58
    00:01:42,820 --> 00:01:43,500
    I'm looking. [THERAPIST]
    
    60
    00:01:44,340 --> 00:01:44,990
    Are [CHILD]
    
    61
    00:01:47,842 --> 00:01:48,280
    you looking? [THERAPIST]
    
    63
    00:01:48,700 --> 00:01:51,320
    I will. [THERAPIST]
    
    65
    00:01:51,500 --> 00:01:52,400
    Let's go sit over here. [THERAPIST]
    
    70
    00:01:52,540 --> 00:01:55,480
    We got a few more for your Skittles. [THERAPIST]
    
    78
    00:01:57,880 --> 00:01:58,300
    What color? [THERAPIST]
    
    80
    00:01:58,580 --> 00:01:59,440
    Blue. [THERAPIST]
    
    81
    00:01:59,680 --> 00:02:00,660
    What color? [THERAPIST]
    
    83
    00:02:00,940 --> 00:02:01,280
    Red. [THERAPIST]
    
    84
    00:02:01,480 --> 00:02:02,340
    What color? [THERAPIST]
    
    86
    00:02:02,720 --> 00:02:03,040
    Green. [THERAPIST]
    
    87
    00:02:03,540 --> 00:02:03,840
    Woo! [THERAPIST]
    
    88
    00:02:04,802 --> 00:02:05,438
    You [THERAPIST]
    
    89
    00:02:06,914 --> 00:02:07,560
    can get up now. [THERAPIST]
    
    `,
    without_speaker: `0
    00:00:01,506 --> 00:00:01,840
    What color?
    
    2
    00:00:02,740 --> 00:00:02,840
    Green.
    
    3
    00:00:05,860 --> 00:00:07,360
    Do this.
    
    8
    00:00:09,760 --> 00:00:10,240
    What color?
    
    10
    00:00:10,540 --> 00:00:10,860
    Yellow.
    
    11
    00:00:11,360 --> 00:00:12,260
    What color?
    
    13
    00:00:13,058 --> 00:00:13,980
    Yellow.
    
    15
    00:00:14,520 --> 00:00:15,420
    Thanks for saying yellow.
    
    19
    00:00:30,840 --> 00:00:30,940
    Ready?
    
    20
    00:00:31,080 --> 00:00:31,806
    What
    
    21
    00:00:33,474 --> 00:00:33,880
    color?
    
    22
    00:00:35,106 --> 00:00:35,280
    Red.
    
    23
    00:00:35,480 --> 00:00:35,710
    What
    
    24
    00:00:36,980 --> 00:00:37,280
    color?
    
    25
    00:00:37,640 --> 00:00:38,340
    Black.
    
    26
    00:00:38,680 --> 00:00:38,846
    Good
    
    27
    00:00:41,160 --> 00:00:41,840
    work, buddy.
    
    30
    00:00:42,160 --> 00:00:43,140
    Nice job.
    
    32
    00:01:10,370 --> 00:01:11,500
    What color?
    
    35
    00:01:11,840 --> 00:01:12,222
    Blue.
    
    36
    00:01:12,940 --> 00:01:13,406
    What
    
    37
    00:01:14,018 --> 00:01:14,240
    color?
    
    38
    00:01:14,920 --> 00:01:14,980
    Red.
    
    39
    00:01:16,460 --> 00:01:16,840
    What color?
    
    41
    00:01:17,160 --> 00:01:17,400
    Blue.
    
    42
    00:01:17,720 --> 00:01:18,270
    What
    
    43
    00:01:18,978 --> 00:01:19,080
    color?
    
    44
    00:01:19,440 --> 00:01:19,940
    Black.
    
    45
    00:01:21,260 --> 00:01:21,600
    What color?
    
    47
    00:01:21,680 --> 00:01:22,880
    Yellow.
    
    49
    00:01:23,120 --> 00:01:23,720
    What color?
    
    51
    00:01:23,960 --> 00:01:24,380
    Yellow.
    
    52
    00:01:24,640 --> 00:01:25,540
    Good work.
    
    54
    00:01:41,760 --> 00:01:42,360
    What are you doing?
    
    58
    00:01:42,820 --> 00:01:43,500
    I'm looking.
    
    60
    00:01:44,340 --> 00:01:44,990
    Are
    
    61
    00:01:47,842 --> 00:01:48,280
    you looking?
    
    63
    00:01:48,700 --> 00:01:51,320
    I will.
    
    65
    00:01:51,500 --> 00:01:52,400
    Let's go sit over here.
    
    70
    00:01:52,540 --> 00:01:55,480
    We got a few more for your Skittles.
    
    78
    00:01:57,880 --> 00:01:58,300
    What color?
    
    80
    00:01:58,580 --> 00:01:59,440
    Blue.
    
    81
    00:01:59,680 --> 00:02:00,660
    What color?
    
    83
    00:02:00,940 --> 00:02:01,280
    Red.
    
    84
    00:02:01,480 --> 00:02:02,340
    What color?
    
    86
    00:02:02,720 --> 00:02:03,040
    Green.
    
    87
    00:02:03,540 --> 00:02:03,840
    Woo!
    
    88
    00:02:04,802 --> 00:02:05,438
    You
    
    89
    00:02:06,914 --> 00:02:07,560
    can get up now.
    
    `,
}
export const srt5 = {
    with_speaker: `0
    00:00:00,066 --> 00:00:04,060
    And a school bus. [THERAPIST]
    
    8
    00:00:04,520 --> 00:00:06,110
    And a helicopter too. [THERAPIST]
    
    12
    00:00:06,818 --> 00:00:09,080
    Yes, and a helicopter flies in the sky. [THERAPIST]
    
    20
    00:00:09,520 --> 00:00:09,820
    Yeah. [THERAPIST]
    
    21
    00:00:09,940 --> 00:00:11,400
    What else flies in the sky? [THERAPIST]
    
    27
    00:00:12,020 --> 00:00:12,720
    Airplane. [THERAPIST]
    
    28
    00:00:12,760 --> 00:00:15,540
    An airplane flies in the sky. [THERAPIST]
    
    34
    00:00:15,580 --> 00:00:16,440
    That's right. [CHILD]
    
    36
    00:00:16,880 --> 00:00:19,640
    And a helicopter flies too. [THERAPIST]
    
    41
    00:00:19,780 --> 00:00:22,300
    Tell me an animal that flies in the sky. [THERAPIST]
    
    50
    00:00:22,640 --> 00:00:22,880
    No. [THERAPIST]
    
    51
    00:00:23,060 --> 00:00:25,140
    An animal can fly in the sky. [THERAPIST]
    
    58
    00:00:25,200 --> 00:00:26,520
    That's a bird. [THERAPIST]
    
    60
    00:00:27,240 --> 00:00:27,840
    A bird. [THERAPIST]
    
    62
    00:00:28,000 --> 00:00:30,800
    A bird can fly in the sky. [THERAPIST]
    
    69
    00:00:30,960 --> 00:00:31,760
    That's right. [THERAPIST]
    
    71
    00:00:32,040 --> 00:00:33,780
    Which one do you want? [THERAPIST]
    
    77
    00:00:34,420 --> 00:00:34,974
    Fire [CHILD]
    
    78
    00:00:36,100 --> 00:00:36,340
    truck. [THERAPIST]
    
    79
    00:00:36,580 --> 00:00:37,500
    Fire truck. [THERAPIST]
    
    81
    00:00:38,580 --> 00:00:43,480
    Six fire trucks. [THERAPIST]
    
    87
    00:00:43,560 --> 00:00:45,880
    Okay, what color is this fire truck? [THERAPIST]
    
    95
    00:00:46,180 --> 00:00:46,700
    Red. [THERAPIST]
    
    96
    00:00:46,800 --> 00:00:47,920
    It is red. [THERAPIST]
    
    99
    00:00:48,060 --> 00:00:49,780
    So here's a red fire truck. [THERAPIST]
    
    105
    00:00:50,100 --> 00:00:54,040
    Here's another fire truck. [THERAPIST]
    
    109
    00:00:54,080 --> 00:00:54,840
    That's two. [CHILD]
    
    111
    00:00:58,000 --> 00:00:58,174
    Oh, [THERAPIST]
    
    112
    00:00:59,600 --> 00:01:01,140
    that one's not red. [THERAPIST]
    
    117
    00:01:01,200 --> 00:01:02,000
    That one is? [THERAPIST]
    
    120
    00:01:02,040 --> 00:01:02,740
    White. [THERAPIST]
    
    121
    00:01:02,840 --> 00:01:05,100
    There's a white fire truck. [THERAPIST]
    
    126
    00:01:05,840 --> 00:01:06,920
    That one. [THERAPIST]
    
    130
    00:01:07,300 --> 00:01:08,860
    Oh, that one's not white. [THERAPIST]
    
    136
    00:01:08,860 --> 00:01:09,240
    It's? [THERAPIST]
    
    137
    00:01:09,260 --> 00:01:11,660
    Red. [THERAPIST]
    
    139
    00:01:11,660 --> 00:01:12,126
    Right. [CHILD]
    
    140
    00:01:12,820 --> 00:01:14,180
    This one's not white. [THERAPIST]
    
    146
    00:01:14,220 --> 00:01:14,660
    It is? [THERAPIST]
    
    148
    00:01:14,960 --> 00:01:15,550
    Red. [THERAPIST]
    
    149
    00:01:17,826 --> 00:01:19,260
    It is red. [THERAPIST]
    
    152
    00:01:19,280 --> 00:01:21,180
    Okay, so here's a white one. [THERAPIST]
    
    158
    00:01:21,460 --> 00:01:23,198
    Let [THERAPIST]
    
    159
    00:01:24,578 --> 00:01:25,246
    me look. [THERAPIST]
    
    161
    00:01:28,000 --> 00:01:28,540
    It's like you. [THERAPIST]
    
    163
    00:01:28,880 --> 00:01:29,900
    It is me. [THERAPIST]
    
    166
    00:01:30,220 --> 00:01:35,800
    Damien, I think that's all the fire trucks that we have. [THERAPIST]
    
    177
    00:01:36,480 --> 00:01:37,980
    Oh, wait. [CHILD]
    
    180
    00:01:38,000 --> 00:01:38,366
    That [THERAPIST]
    
    181
    00:01:39,394 --> 00:01:41,660
    one has something you climb on. [THERAPIST]
    
    187
    00:01:41,900 --> 00:01:43,580
    That one has a? [THERAPIST]
    
    192
    00:01:43,640 --> 00:01:44,480
    How do you know? [CHILD]
    
    196
    00:01:44,940 --> 00:01:46,080
    It has a ladder. [THERAPIST]
    
    200
    00:01:46,580 --> 00:01:47,198
    It [THERAPIST]
    
    201
    00:01:47,970 --> 00:01:48,920
    has a ladder. [THERAPIST]
    
    204
    00:01:49,080 --> 00:01:50,060
    Take the ladder. [THERAPIST]
    
    207
    00:01:50,840 --> 00:01:53,140
    Yep, that one has a ladder. [THERAPIST]
    
    213
    00:01:53,400 --> 00:01:56,000
    Let me look for another one. [THERAPIST]
    
    219
    00:01:58,000 --> 00:01:59,220
    Is this a fire truck? [THERAPIST]
    
    225
    00:02:00,098 --> 00:02:02,760
    It's a stop sign. [THERAPIST]
    
    232
    00:02:02,860 --> 00:02:04,560
    Let's get the school bus. [THERAPIST]
    
    237
    00:02:05,040 --> 00:02:06,206
    Hey, [THERAPIST]
    
    238
    00:02:10,082 --> 00:02:11,400
    look what a school bus has. [THERAPIST]
    
    244
    00:02:11,520 --> 00:02:12,380
    The school bus has? [CHILD]
    
    248
    00:02:12,540 --> 00:02:13,880
    Wheels. [THERAPIST]
    
    250
    00:02:13,880 --> 00:02:15,540
    What else does a school bus have? [THERAPIST]
    
    257
    00:02:16,080 --> 00:02:19,560
    A door. [THERAPIST]
    
    261
    00:02:19,740 --> 00:02:21,500
    And it has a? [THERAPIST]
    
    265
    00:02:21,600 --> 00:02:23,800
    A stop sign. [THERAPIST]
    
    271
    00:02:24,000 --> 00:02:24,940
    And it has? [THERAPIST]
    
    274
    00:02:25,140 --> 00:02:27,280
    Oh, a school bus also has some? [THERAPIST]
    
    282
    00:02:28,000 --> 00:02:28,542
    And windows. [THERAPIST]
    
    284
    00:02:29,730 --> 00:02:30,000
    Windows. [THERAPIST]
    
    285
    00:02:30,140 --> 00:02:31,840
    And where do people sit? [THERAPIST]
    
    290
    00:02:31,960 --> 00:02:32,960
    They sit in their? [THERAPIST]
    
    294
    00:02:33,260 --> 00:02:34,700
    A school bus. [THERAPIST]
    
    297
    00:02:34,880 --> 00:02:36,820
    They sit in the school bus in the seats. [THERAPIST]
    
    306
    00:02:37,160 --> 00:02:38,520
    Yeah. [THERAPIST]
    
    308
    00:02:38,540 --> 00:02:39,440
    What do you sit in? [THERAPIST]
    
    313
    00:02:39,680 --> 00:02:40,800
    A school bus. [THERAPIST]
    
    316
    00:02:40,960 --> 00:02:42,440
    A seat. [THERAPIST]
    
    320
    00:02:42,560 --> 00:02:43,120
    Yes. [THERAPIST]
    
    321
    00:02:43,160 --> 00:02:44,060
    Where do you sit? [THERAPIST]
    
    325
    00:02:44,740 --> 00:02:45,406
    A seat. [THERAPIST]
    
    327
    00:02:46,360 --> 00:02:47,300
    Seat. [THERAPIST]
    
    328
    00:02:47,300 --> 00:02:48,700
    In a seat. [THERAPIST]
    
    `,
    without_speaker: `0
    00:00:00,066 --> 00:00:04,060
    And a school bus.
    
    8
    00:00:04,520 --> 00:00:06,110
    And a helicopter too.
    
    12
    00:00:06,818 --> 00:00:09,080
    Yes, and a helicopter flies in the sky.
    
    20
    00:00:09,520 --> 00:00:09,820
    Yeah.
    
    21
    00:00:09,940 --> 00:00:11,400
    What else flies in the sky?
    
    27
    00:00:12,020 --> 00:00:12,720
    Airplane.
    
    28
    00:00:12,760 --> 00:00:15,540
    An airplane flies in the sky.
    
    34
    00:00:15,580 --> 00:00:16,440
    That's right.
    
    36
    00:00:16,880 --> 00:00:19,640
    And a helicopter flies too.
    
    41
    00:00:19,780 --> 00:00:22,300
    Tell me an animal that flies in the sky.
    
    50
    00:00:22,640 --> 00:00:22,880
    No.
    
    51
    00:00:23,060 --> 00:00:25,140
    An animal can fly in the sky.
    
    58
    00:00:25,200 --> 00:00:26,520
    That's a bird.
    
    60
    00:00:27,240 --> 00:00:27,840
    A bird.
    
    62
    00:00:28,000 --> 00:00:30,800
    A bird can fly in the sky.
    
    69
    00:00:30,960 --> 00:00:31,760
    That's right.
    
    71
    00:00:32,040 --> 00:00:33,780
    Which one do you want?
    
    77
    00:00:34,420 --> 00:00:34,974
    Fire
    
    78
    00:00:36,100 --> 00:00:36,340
    truck.
    
    79
    00:00:36,580 --> 00:00:37,500
    Fire truck.
    
    81
    00:00:38,580 --> 00:00:43,480
    Six fire trucks.
    
    87
    00:00:43,560 --> 00:00:45,880
    Okay, what color is this fire truck?
    
    95
    00:00:46,180 --> 00:00:46,700
    Red.
    
    96
    00:00:46,800 --> 00:00:47,920
    It is red.
    
    99
    00:00:48,060 --> 00:00:49,780
    So here's a red fire truck.
    
    105
    00:00:50,100 --> 00:00:54,040
    Here's another fire truck.
    
    109
    00:00:54,080 --> 00:00:54,840
    That's two.
    
    111
    00:00:58,000 --> 00:00:58,174
    Oh,
    
    112
    00:00:59,600 --> 00:01:01,140
    that one's not red.
    
    117
    00:01:01,200 --> 00:01:02,000
    That one is?
    
    120
    00:01:02,040 --> 00:01:02,740
    White.
    
    121
    00:01:02,840 --> 00:01:05,100
    There's a white fire truck.
    
    126
    00:01:05,840 --> 00:01:06,920
    That one.
    
    130
    00:01:07,300 --> 00:01:08,860
    Oh, that one's not white.
    
    136
    00:01:08,860 --> 00:01:09,240
    It's?
    
    137
    00:01:09,260 --> 00:01:11,660
    Red.
    
    139
    00:01:11,660 --> 00:01:12,126
    Right.
    
    140
    00:01:12,820 --> 00:01:14,180
    This one's not white.
    
    146
    00:01:14,220 --> 00:01:14,660
    It is?
    
    148
    00:01:14,960 --> 00:01:15,550
    Red.
    
    149
    00:01:17,826 --> 00:01:19,260
    It is red.
    
    152
    00:01:19,280 --> 00:01:21,180
    Okay, so here's a white one.
    
    158
    00:01:21,460 --> 00:01:23,198
    Let
    
    159
    00:01:24,578 --> 00:01:25,246
    me look.
    
    161
    00:01:28,000 --> 00:01:28,540
    It's like you.
    
    163
    00:01:28,880 --> 00:01:29,900
    It is me.
    
    166
    00:01:30,220 --> 00:01:35,800
    Damien, I think that's all the fire trucks that we have.
    
    177
    00:01:36,480 --> 00:01:37,980
    Oh, wait.
    
    180
    00:01:38,000 --> 00:01:38,366
    That
    
    181
    00:01:39,394 --> 00:01:41,660
    one has something you climb on.
    
    187
    00:01:41,900 --> 00:01:43,580
    That one has a?
    
    192
    00:01:43,640 --> 00:01:44,480
    How do you know?
    
    196
    00:01:44,940 --> 00:01:46,080
    It has a ladder.
    
    200
    00:01:46,580 --> 00:01:47,198
    It
    
    201
    00:01:47,970 --> 00:01:48,920
    has a ladder.
    
    204
    00:01:49,080 --> 00:01:50,060
    Take the ladder.
    
    207
    00:01:50,840 --> 00:01:53,140
    Yep, that one has a ladder.
    
    213
    00:01:53,400 --> 00:01:56,000
    Let me look for another one.
    
    219
    00:01:58,000 --> 00:01:59,220
    Is this a fire truck?
    
    225
    00:02:00,098 --> 00:02:02,760
    It's a stop sign.
    
    232
    00:02:02,860 --> 00:02:04,560
    Let's get the school bus.
    
    237
    00:02:05,040 --> 00:02:06,206
    Hey,
    
    238
    00:02:10,082 --> 00:02:11,400
    look what a school bus has.
    
    244
    00:02:11,520 --> 00:02:12,380
    The school bus has?
    
    248
    00:02:12,540 --> 00:02:13,880
    Wheels.
    
    250
    00:02:13,880 --> 00:02:15,540
    What else does a school bus have?
    
    257
    00:02:16,080 --> 00:02:19,560
    A door.
    
    261
    00:02:19,740 --> 00:02:21,500
    And it has a?
    
    265
    00:02:21,600 --> 00:02:23,800
    A stop sign.
    
    271
    00:02:24,000 --> 00:02:24,940
    And it has?
    
    274
    00:02:25,140 --> 00:02:27,280
    Oh, a school bus also has some?
    
    282
    00:02:28,000 --> 00:02:28,542
    And windows.
    
    284
    00:02:29,730 --> 00:02:30,000
    Windows.
    
    285
    00:02:30,140 --> 00:02:31,840
    And where do people sit?
    
    290
    00:02:31,960 --> 00:02:32,960
    They sit in their?
    
    294
    00:02:33,260 --> 00:02:34,700
    A school bus.
    
    297
    00:02:34,880 --> 00:02:36,820
    They sit in the school bus in the seats.
    
    306
    00:02:37,160 --> 00:02:38,520
    Yeah.
    
    308
    00:02:38,540 --> 00:02:39,440
    What do you sit in?
    
    313
    00:02:39,680 --> 00:02:40,800
    A school bus.
    
    316
    00:02:40,960 --> 00:02:42,440
    A seat.
    
    320
    00:02:42,560 --> 00:02:43,120
    Yes.
    
    321
    00:02:43,160 --> 00:02:44,060
    Where do you sit?
    
    325
    00:02:44,740 --> 00:02:45,406
    A seat.
    
    327
    00:02:46,360 --> 00:02:47,300
    Seat.
    
    328
    00:02:47,300 --> 00:02:48,700
    In a seat.
    
    `,
}
export const srt6 = {
    with_speaker: `0
    00:00:00,000 --> 00:00:00,100
    Okay. [THERAPIST]
    
    1
    00:00:00,220 --> 00:00:00,414
    Which [THERAPIST]
    
    2
    00:00:02,780 --> 00:00:02,880
    one? [THERAPIST]
    
    3
    00:00:03,460 --> 00:00:03,920
    This one. [THERAPIST]
    
    6
    00:00:05,060 --> 00:00:07,140
    I want a whole bag. [THERAPIST]
    
    11
    00:00:07,860 --> 00:00:08,940
    You want a whole bag? [THERAPIST]
    
    16
    00:00:09,420 --> 00:00:09,620
    Yeah. [THERAPIST]
    
    17
    00:00:10,760 --> 00:00:11,760
    Right now you can have one. [THERAPIST]
    
    23
    00:00:12,514 --> 00:00:13,340
    One? [THERAPIST]
    
    25
    00:00:13,540 --> 00:00:14,160
    I want two. [THERAPIST]
    
    28
    00:00:15,120 --> 00:00:15,980
    Okay, hold on. [THERAPIST]
    
    31
    00:00:21,280 --> 00:00:21,600
    Which one? [THERAPIST]
    
    33
    00:00:22,560 --> 00:00:22,820
    Okay. [THERAPIST]
    
    34
    00:00:36,802 --> 00:00:38,942
    I want [THERAPIST]
    
    36
    00:00:41,640 --> 00:00:42,440
    it like that. [THERAPIST]
    
    39
    00:00:42,740 --> 00:00:43,580
    That's okay. [THERAPIST]
    
    41
    00:00:44,200 --> 00:00:44,780
    Okay. [THERAPIST]
    
    42
    00:00:47,042 --> 00:00:47,400
    Which one? [THERAPIST]
    
    44
    00:00:47,580 --> 00:00:52,350
    Which [THERAPIST]
    
    45
    00:00:53,640 --> 00:00:56,080
    one? [THERAPIST]
    
    46
    00:01:00,220 --> 00:01:08,900
    Which one? [THERAPIST]
    
    50
    00:01:16,834 --> 00:01:18,078
    Are [THERAPIST]
    
    51
    00:01:27,810 --> 00:01:28,280
    you still chewing? [THERAPIST]
    
    54
    00:01:43,580 --> 00:01:43,742
    Which [THERAPIST]
    
    55
    00:01:48,580 --> 00:01:48,680
    one? [THERAPIST]
    
    56
    00:01:53,346 --> 00:01:54,620
    Skittles are your favorite? [THERAPIST]
    
    60
    00:01:55,120 --> 00:01:56,460
    Do you love Skittles? [THERAPIST]
    
    64
    00:01:56,680 --> 00:01:57,660
    Okay. [CHILD]
    
    65
    00:01:59,138 --> 00:01:59,660
    That's a big one. [THERAPIST]
    
    `,
    without_speaker: `0
    00:00:00,000 --> 00:00:00,100
    Okay.
    
    1
    00:00:00,220 --> 00:00:00,414
    Which
    
    2
    00:00:02,780 --> 00:00:02,880
    one?
    
    3
    00:00:03,460 --> 00:00:03,920
    This one.
    
    6
    00:00:05,060 --> 00:00:07,140
    I want a whole bag.
    
    11
    00:00:07,860 --> 00:00:08,940
    You want a whole bag?
    
    16
    00:00:09,420 --> 00:00:09,620
    Yeah.
    
    17
    00:00:10,760 --> 00:00:11,760
    Right now you can have one.
    
    23
    00:00:12,514 --> 00:00:13,340
    One?
    
    25
    00:00:13,540 --> 00:00:14,160
    I want two.
    
    28
    00:00:15,120 --> 00:00:15,980
    Okay, hold on.
    
    31
    00:00:21,280 --> 00:00:21,600
    Which one?
    
    33
    00:00:22,560 --> 00:00:22,820
    Okay.
    
    34
    00:00:36,802 --> 00:00:38,942
    I want
    
    36
    00:00:41,640 --> 00:00:42,440
    it like that.
    
    39
    00:00:42,740 --> 00:00:43,580
    That's okay.
    
    41
    00:00:44,200 --> 00:00:44,780
    Okay.
    
    42
    00:00:47,042 --> 00:00:47,400
    Which one?
    
    44
    00:00:47,580 --> 00:00:52,350
    Which
    
    45
    00:00:53,640 --> 00:00:56,080
    one?
    
    46
    00:01:00,220 --> 00:01:24,420
    Which one?
    
    57
    00:01:24,520 --> 00:01:28,400
    Are you still chewing?
    
    62
    00:01:28,660 --> 00:01:29,310
    Hm?
    
    63
    00:01:43,580 --> 00:01:43,742
    Which
    
    64
    00:01:48,580 --> 00:01:48,680
    one?
    
    65
    00:01:53,346 --> 00:01:54,620
    Skittles are your favorite?
    
    69
    00:01:55,120 --> 00:01:56,460
    Do you love Skittles?
    
    73
    00:01:56,680 --> 00:01:57,660
    Okay.
    
    74
    00:01:59,138 --> 00:01:59,660
    That's a big one.
    
    `,
}
export const srt7 = {
    with_speaker: `0
    00:00:00,840 --> 00:00:03,660
    No, no, all done. [THERAPIST]
    
    6
    00:00:05,058 --> 00:00:05,758
    Come [THERAPIST]
    
    7
    00:00:06,818 --> 00:00:07,020
    on. [THERAPIST]
    
    8
    00:00:07,620 --> 00:00:11,500
    You're okay. [THERAPIST]
    
    12
    00:00:12,100 --> 00:00:13,520
    Just take a big breath. [THERAPIST]
    
    17
    00:00:16,160 --> 00:00:17,180
    Come on. [CHILD]
    
    19
    00:00:20,578 --> 00:00:51,742
    You're okay. [THERAPIST]
    
    25
    00:00:56,000 --> 00:00:57,580
    Come on. [THERAPIST]
    
    27
    00:00:59,138 --> 00:00:59,580
    You're okay. [THERAPIST]
    
    29
    00:01:08,290 --> 00:01:09,060
    Come on. [CHILD]
    
    31
    00:01:15,520 --> 00:01:16,520
    You're okay. [THERAPIST]
    
    33
    00:01:32,060 --> 00:01:32,700
    What's wrong, darling? [THERAPIST]
    
    37
    00:01:33,000 --> 00:01:34,740
    Look at mommy. [THERAPIST]
    
    40
    00:01:34,920 --> 00:01:36,540
    Look at me. [THERAPIST]
    
    43
    00:01:36,600 --> 00:01:37,180
    You're okay. [THERAPIST]
    
    45
    00:02:01,634 --> 00:02:07,580
    Like. [THERAPIST]
    
    `,
    without_speaker: `0
    00:00:00,840 --> 00:00:03,660
    No, no, all done.
    
    6
    00:00:05,058 --> 00:00:05,758
    Come
    
    7
    00:00:06,818 --> 00:00:07,020
    on.
    
    8
    00:00:07,620 --> 00:00:11,500
    You're okay.
    
    12
    00:00:12,100 --> 00:00:13,520
    Just take a big breath.
    
    17
    00:00:16,160 --> 00:00:17,180
    Come on.
    
    19
    00:00:20,578 --> 00:00:51,742
    You're okay.
    
    25
    00:00:56,000 --> 00:00:57,580
    Come on.
    
    27
    00:00:59,138 --> 00:00:59,580
    You're okay.
    
    29
    00:01:08,290 --> 00:01:09,060
    Come on.
    
    31
    00:01:15,520 --> 00:01:16,520
    You're okay.
    
    33
    00:01:32,060 --> 00:01:32,700
    What's wrong, darling?
    
    37
    00:01:33,000 --> 00:01:34,740
    Look at mommy.
    
    40
    00:01:34,920 --> 00:01:36,540
    Look at me.
    
    43
    00:01:36,600 --> 00:01:37,180
    You're okay.
    
    45
    00:02:01,634 --> 00:02:07,870
    What's wrong?
    
    `,
}
