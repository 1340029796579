export const videosDomainData = {
  video1: [
    {
      id: 1,
      startTime: 1,
      endTime: 6.334,
      domain: 'Receptive Communication',
    },
    {
      id: 2,
      startTime: 7.86,
      endTime: 11.518,
      domain: 'Receptive Communication',
    },
    {
      id: 3,
      startTime: 12.226,
      endTime: 18.718,
      domain: 'Receptive Communication',
    },
    {
      id: 4,
      startTime: 19.7,
      endTime: 21.1,
      domain: 'Receptive Communication',
    },
    {
      id: 5,
      startTime: 27.65,
      endTime: 32.286,
      domain: 'Receptive Communication',
    },
    {
      id: 6,
      startTime: 34.946,
      endTime: 42.346,
      domain: 'Receptive Communication',
    },
  ],
  video2: [
    {
      id: 1,
      startTime: 1,
      endTime: 23.54,
      domain: 'Engagement',
    },
    {
      id: 2,
      startTime: 23.96,
      endTime: 34.04,
      domain: 'Mand',
    },
    {
      id: 3,
      startTime: 55,
      endTime: 63.32,
      domain: 'Mand',
    },
    {
      id: 4,
      startTime: 64.66,
      endTime: 73.76,
      domain: 'Mand',
    },
    {
      id: 5,
      startTime: 73.96,
      endTime: 90.22,
      domain: 'Mand',
    },
    {
      id: 6,
      startTime: 90.36,
      endTime: 101.7,
      domain: 'Mand',
    },
    {
      id: 7,
      startTime: 101.9,
      endTime: 121.3,
      domain: 'Play',
    },
  ],
  video3: [],
  video4: [
    {
      id: 1,
      startTime: 1.5,
      endTime: 2.84,
      domain: 'Tact',
    },
    {
      id: 2,
      startTime: 5.86,
      endTime: 7.36,
      domain: 'Imitation',
    },
    {
      id: 3,
      startTime: 9.76,
      endTime: 11.36,
      domain: 'Tact',
    },
    {
      id: 4,
      startTime: 11.36,
      endTime: 15.43,
      domain: 'Tact',
    },
    {
      id: 5,
      startTime: 30.84,
      endTime: 35.28,
      domain: 'Tact',
    },
    {
      id: 6,
      startTime: 35.48,
      endTime: 43.14,
      domain: 'Tact',
    },
    {
      id: 7,
      startTime: 70.37,
      endTime: 72.222,
      domain: 'Tact',
    },
    {
      id: 8,
      startTime: 72.94,
      endTime: 74.98,
      domain: 'Tact',
    },
    {
      id: 9,
      startTime: 76.46,
      endTime: 77.4,
      domain: 'Tact',
    },
    {
      id: 10,
      startTime: 77.72,
      endTime: 79.94,
      domain: 'Tact',
    },
    {
      id: 11,
      startTime: 81.26,
      endTime: 82.88,
      domain: 'Tact',
    },
    {
      id: 12,
      startTime: 83.12,
      endTime: 85.54,
      domain: 'Tact',
    },
    {
      id: 13,
      startTime: 101.76,
      endTime: 103.5,
      domain: 'Mand',
    },
    {
      id: 14,
      startTime: 104.34,
      endTime: 111.32,
      domain: 'Mand',
    },
    {
      id: 15,
      startTime: 111.5,
      endTime: 112.4,
      domain: 'Receptive Communication',
    },
    {
      id: 16,
      startTime: 117.88,
      endTime: 119.44,
      domain: 'Tact',
    },
    {
      id: 17,
      startTime: 119.68,
      endTime: 121.28,
      domain: 'Tact',
    },
    {
      id: 18,
      startTime: 121.48,
      endTime: 123.84,
      domain: 'Tact',
    },
  ],
  video5: [
    {
      id: 1,
      startTime: 0,
      endTime: 4.06,
      domain: 'Tact',
    },
    {
      id: 2,
      startTime: 4.52,
      endTime: 9.82,
      domain: 'Tact',
    },
    {
      id: 3,
      startTime: 9.94,
      endTime: 16.44,
      domain: 'Intraverbal',
    },
    {
      id: 4,
      startTime: 19.78,
      endTime: 31.76,
      domain: 'Intraverbal',
    },
    {
      id: 5,
      startTime: 32.04,
      endTime: 37.5,
      domain: 'Tact',
    },
    {
      id: 6,
      startTime: 43.56,
      endTime: 49.78,
      domain: 'Tact',
    },
    {
      id: 7,
      startTime: 59.6,
      endTime: 65.1,
      domain: 'Tact',
    },
    {
      id: 8,
      startTime: 67.3,
      endTime: 69.26,
      domain: 'Tact',
    },
    {
      id: 9,
      startTime: 98,
      endTime: 113.14,
      domain: 'Tact',
    },
    {
      id: 10,
      startTime: 118,
      endTime: 122.76,
      domain: 'Tact',
    },
    {
      id: 11,
      startTime: 130.08,
      endTime: 133.88,
      domain: 'Tact',
    },
    {
      id: 12,
      startTime: 133.88,
      endTime: 139.56,
      domain: 'Tact',
    },
    {
      id: 13,
      startTime: 139.74,
      endTime: 143.8,
      domain: 'Tact',
    },
    {
      id: 14,
      startTime: 144,
      endTime: 150,
      domain: 'Tact',
    },
    {
      id: 15,
      startTime: 150.14,
      endTime: 163.12,
      domain: 'Tact',
    },
    {
      id: 16,
      startTime: 163.16,
      endTime: 168.7,
      domain: 'Tact',
    },
  ],
  video6: [
    {
      id: 1,
      startTime: 0,
      endTime: 13.34,
      domain: 'Mand',
    },
    {
      id: 2,
      startTime: 13.54,
      endTime: 15.98,
      domain: 'Mand',
    },
    {
      id: 3,
      startTime: 21.28,
      endTime: 44.78,
      domain: 'Mand',
    },
    {
      id: 4,
      startTime: 47.04,
      endTime: 56.08,
      domain: 'Mand',
    },
    {
      id: 5,
      startTime: 60.22,
      endTime: 89.31,
      domain: 'Mand',
    },
    {
      id: 6,
      startTime: 103.58,
      endTime: 119.66,
      domain: 'Mand',
    },
  ],
  video7: [],
}
