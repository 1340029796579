import user from './user/reducer'
import settings from './settings/reducers'
import videosActionsReducer from './videoAndTranscript/reducer'
import supervision from './Supervision/reducer'
import templates from './Templates/reducer'
import sessionAnalysis from './SessionAnalysis/reducer'
import socialOrientation from './SocialOrientation/reducer'
import assessmentBuilder from './AssessmentBuilder/reducer'

const reducer = {
  user,
  settings,
  videoAndTranscript: videosActionsReducer,
  supervision,
  templates,
  sessionAnalysis,
  socialOrientation,
  assessmentBuilder,
}

export default reducer
