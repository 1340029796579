export const data = [
  {
    id: 1,
    startTime: 18,
    endTime: 42,
    domain: 'Listener Response',
    targets: 'Where is cat',
    correctCount: 1,
    correctDescription: 'child picked and gave the picture of cat to the therapist',
    incorrectCount: 1,
    incorrectDescription: "Child didn't give the picture of cat to the therapist",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 50,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 2,
  },
  {
    id: 39,
    startTime: 102,
    endTime: 110,
    domain: 'Echoics - animal sound copy',
    targets: "cat goes 'meow'",
    correctCount: 1,
    correctDescription: "Child repeated the sound saying 'meow'",
    incorrectCount: 1,
    incorrectDescription: "Child did not repeate the sound",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 50,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 2,
  },
  {
    id: 2,
    startTime: 50,
    endTime: 102,
    domain: 'Listener Response',
    targets: 'Where is cat',
    correctCount: 2,
    correctDescription: 'child picked and gave the picture of cat to the therapist',
    incorrectCount: 4,
    incorrectDescription: "Child didn't give the picture of cat to the therapist",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 33,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 12,
  },
  {
    id: 3,
    startTime: 67,
    endTime: 172,
    domain: 'Reinforcement assessment',
    targets: 'Do you want donald duck\nDo you want big bird',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 0,
    incorrectDescription: 'Child didnt choose',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 12,
  },
  {
    id: 4,
    startTime: 183,
    endTime: 204,
    domain: 'Listener Response',
    targets: 'Where is cat',
    correctCount: 1,
    correctDescription: 'child picked and gave the picture of cat to the therapist',
    incorrectCount: 2,
    incorrectDescription: "Child didn't give the picture of cat to the therapist",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 33,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 15,
  },
  {
    id: 5,
    startTime: 265,
    endTime: 282,
    domain: 'Listener Response\nVisual perception',
    targets: 'where is black',
    correctCount: 1,
    correctDescription: 'Therapist helped the child by giving gestural prompt(pointing on black) for correct response.',
    incorrectCount: 1,
    incorrectDescription:
      'Therapist said try again without praising the child for correct response.',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 50,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 16,
  },
  {
    id: 6,
    startTime: 281,
    endTime: 289,
    domain: 'Listener Response\nVisual perception',
    targets: 'Brown',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 17,
  },
  {
    id: 7,
    startTime: 290,
    endTime: 300,
    domain: 'Listener Response\nVisual perception',
    targets: 'Purple',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 18,
  },
  {
    id: 8,
    startTime: 300,
    endTime: 306,
    domain: 'Listener Response\nVisual perception',
    targets: 'Red',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 19,
  },
  {
    id: 9,
    startTime: 307,
    endTime: 316,
    domain: 'Listener Response\nVisual perception',
    targets: 'Green',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 20,
  },
  {
    id: 10,
    startTime: 317,
    endTime: 327,
    domain: 'Listener Response\nVisual perception',
    targets: 'Blue',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 21,
  },
  {
    id: 11,
    startTime: 328,
    endTime: 334,
    domain: 'Listener Response\nVisual perception',
    targets: 'Yellow',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 22,
  },
  {
    id: 12,
    startTime: 335,
    endTime: 336,
    domain: 'Listener Response\nVisual perception',
    targets: 'Orange',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 23,
  },
  {
    id: 13,
    startTime: 337,
    endTime: 343,
    domain: 'Listener Response\nVisual perception',
    targets: 'Grey',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 24,
  },
  {
    id: 14,
    startTime: 343,
    endTime: 356,
    domain: 'Listener Response\nVisual perception',
    targets: 'Pink',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 25,
  },
  {
    id: 40,
    startTime: 358,
    endTime: 359,
    domain: 'Listener Response\nImitation',
    targets: 'High five',
    correctCount: 1,
    correctDescription: 'Child responded by giving hi-five, no prompts were given',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 25,
  },
  {
    id: 41,
    startTime: 359,
    endTime: 361,
    domain: 'Listener Response\nImitation',
    targets: 'Dance Party',
    correctCount: 1,
    correctDescription: 'Child responded by dancing, no prompts were given',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 25,
  },
  {
    id: 15,
    startTime: 397,
    endTime: 404,
    domain: 'Listener Response\nVisual perception',
    targets: 'Three',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 26,
  },
  {
    id: 16,
    startTime: 407,
    endTime: 408,
    domain: 'Listener Response\nVisual perception',
    targets: 'Eight',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 1,
    incorrectDescription: "Child didn't take the number from the therapist.",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 27,
  },
  {
    id: 17,
    startTime: 408,
    endTime: 412,
    domain: 'Listener Response\nVisual perception',
    targets: 'One',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 28,
  },
  {
    id: 18,
    startTime: 414,
    endTime: 420,
    domain: 'Listener Response\nVisual perception',
    targets: 'Eight',
    correctCount: 1,
    correctDescription: '',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 29,
  },
  {
    id: 19,
    startTime: 421,
    endTime: 430,
    domain: 'Listener Response\nVisual perception',
    targets: 'Ten',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 30,
  },
  {
    id: 20,
    startTime: 431,
    endTime: 437,
    domain: 'Mand, echoics',
    targets: 'Two',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 31,
  },
  {
    id: 21,
    startTime: 447,
    endTime: 452,
    domain: 'Mand, echoics',
    targets: 'Four',
    correctCount: 1,
    correctDescription: 'Therapist moved on to another target. No prompts were given.',
    incorrectCount: 1,
    incorrectDescription: "Child didn't repeat after the therapist",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 50,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 33,
  },
  {
    id: 22,
    startTime: 457,
    endTime: 462,
    domain: 'Mand, echoics',
    targets: 'Five',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 1,
    incorrectDescription:
      "Child didn't ask for number five. Therapist later labelled the number. No prompts were given to child",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 34,
  },
  {
    id: 23,
    startTime: 467,
    endTime: 472,
    domain: 'Mand, echoics',
    targets: 'Nine',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 1,
    incorrectDescription:
      "Child didn't ask for number nine. Therapist later labelled the number. No prompts were given to child",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 35,
  },
  {
    id: 24,
    startTime: 477,
    endTime: 481,
    domain: 'Mand, echoics',
    targets: 'Six',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 1,
    incorrectDescription:
      "Child didn't ask for number six. Therapist later labelled the number. No prompts were given to child",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 36,
  },
  {
    id: 25,
    startTime: 487,
    endTime: 491,
    domain: 'Mand echoics',
    targets: 'Seven',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 1,
    incorrectDescription:
      "Child didn't ask for number seven. Therapist later labelled the number. No prompts were given to child",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 37,
  },
  {
    id: 26,
    startTime: 497,
    endTime: 500,
    domain: 'Reinforcement delivery',
    targets: 'Social praise with clap',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 37,
  },
  {
    id: 27,
    startTime: 505,
    endTime: 510,
    domain: 'Listener Response',
    targets: 'High five',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 2,
    incorrectDescription: "The child didn't give any response. No prompts were given to the child",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 39,
  },
  {
    id: 28,
    startTime: 513,
    endTime: 517,
    domain: 'Reinforcement assessment',
    targets: 'Do you want',
    correctCount: 1,
    correctDescription: 'Child took it from therapist',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 40,
  },
  {
    id: 29,
    startTime: 518,
    endTime: 538,
    domain: 'Mand',
    targets: 'Hands on cheek for warm (with sign)',
    correctCount: 1,
    correctDescription: "Child took therapist's hand to his cheeks",
    incorrectCount: 1,
    incorrectDescription: "Child didn't mand after the therapist's prompt",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 50,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 42,
  },
  {
    id: 31,
    startTime: 509,
    endTime: 511,
    domain: 'Mand',
    targets: 'All done (with sign)',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 1,
    incorrectDescription:
      "Therapist prompted the child to do the sign. The child didn't perform the sign on his own.",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 44,
  },
  {
    id: 32,
    startTime: 551,
    endTime: 555,
    domain: 'Listener Response',
    targets: 'Touch head',
    correctCount: 1,
    correctDescription: 'Child did the action without any prompt.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 45,
  },
  {
    id: 33,
    startTime: 560,
    endTime: 568,
    domain: 'Listener Response',
    targets: 'Touch nose',
    correctCount: 1,
    correctDescription: 'Child touched his nose in the second trial without any prompt.',
    incorrectCount: 1,
    incorrectDescription: 'Child did the error by touching his cheeks the first time.',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 50,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 47,
  },
  {
    id: 34,
    startTime: 571,
    endTime: 578,
    domain: 'Listener Response',
    targets: 'Touch eyes',
    correctCount: 1,
    correctDescription: "Child took therapist's hand and pointed towards his eyes.",
    incorrectCount: 2,
    incorrectDescription: 'Child touched his cheeks instead of his eyes.',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 33,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 50,
  },
  {
    id: 35,
    startTime: 588,
    endTime: 595,
    domain: 'Listener response',
    targets: 'Ears, Touch your ears',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 2,
    incorrectDescription: "Child didn't touch his ears",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 52,
  },
  {
    id: 36,
    startTime: 604,
    endTime: 610,
    domain: 'Listener response',
    targets: 'Ears',
    correctCount: 1,
    correctDescription: 'Child touched his ears without any prompts.',
    incorrectCount: 0,
    incorrectDescription: '',
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 100,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 53,
  },
  {
    id: 37,
    startTime: 611,
    endTime: 619,
    domain: 'Listener response',
    targets: 'Mouth, touch your mouth',
    correctCount: 1,
    correctDescription: 'Child touched his mouth without any prompts.',
    incorrectCount: 1,
    incorrectDescription: "Child didn't touch his ears on the first trial",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 50,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 55,
  },
  {
    id: 38,
    startTime: 628,
    endTime: 635,
    domain: 'Listener response',
    targets: 'Touch shoulder',
    correctCount: 1,
    correctDescription: 'Child touched his shoulder without any prompts.',
    incorrectCount: 2,
    incorrectDescription: "Child didn't touch his shoulder",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 33,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 58,
  },
  {
    id: 42,
    startTime: 648,
    endTime: 650,
    domain: 'Listener response',
    targets: 'Touch belly',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 1,
    incorrectDescription: "Child didn't touch his belly, no prompt was given",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 58,
  },
  {
    id: 43,
    startTime: 681,
    endTime: 691,
    domain: 'Listener response',
    targets: 'Touch belly',
    correctCount: 1,
    correctDescription: 'Child touched his belly without any prompt.',
    incorrectCount: 1,
    incorrectDescription: "Child didn't touch his belly",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 50,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 58,
  },
  {
    id: 44,
    startTime: 697,
    endTime: 704,
    domain: 'Listener response',
    targets: 'High five',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 2,
    incorrectDescription: "Child didn't give high five",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 58,
  },
  {
    id: 45,
    startTime: 713,
    endTime: 720,
    domain: 'Listener response',
    targets: 'Touch head',
    correctCount: 0,
    correctDescription: '',
    incorrectCount: 2,
    incorrectDescription: "Child didn't touch his head",
    outcome: '',
    comment: [],
    status: 'pending',
    successPercentage: 0,
    currentPhase: 'Generalization',
    phaseChange: '',
    proposedBy: 'System',
    totalTrialsCumulative: 58,
  },
]

export const data2 = [
  {
    "id": 1,
    "domain": "Mand",
    "targets": "do you need a moment to play with your toys?",
    "description": "The therapist could teach the child 'yes/no' answers (verbally/sign language) to her questions when she was asking 'do you need a moment to play with your toys?'. The therapist could have contrived a verbal prompt for saying 'yes/no' or physical prompt for 'yes/no' sign and prompt fade trial.",
    "startTime": 173,
    "endTime": 182,
    status: 'pending',
    "comment": []
  },
  {
    "id": 2,
    "domain": "Mand",
    "targets": "where is pink",
    "description": "The therapist gave a verbal prompt for mand for information, 'Where is pink?'. The child did not repeat the therapist. The therapist could have contrived another prompt and prompt fade trial.",
    "startTime": 341,
    "endTime": 350,
    status: 'pending',
    "comment": []
  },
  {
    "id": 3,
    "domain": "Mand",
    "targets": "nine",
    "description": "The therapist could have waited for the child to repeat the mand for 'nine' before handing over the nine cards to him.",
    "startTime": 448,
    "endTime": 452,
    status: 'pending',
    "comment": []
  },
  {
    "id": 4,
    "domain": "Mand with sign",
    "targets": "warm",
    "description": "The child wanted the therapist to touch his face. The therapist could have used this opportunity to teach the child to request the therapist for an action to touch his face using a sign or by saying, 'warm face'/'touch face'",
    "startTime": 488,
    "endTime": 498,
    status: 'pending',
    "comment": []
  },
  {
    "id": 5,
    "domain": "Mand",
    "targets": "sea toys",
    "description": "The child reached out to some sea toys that were on the table. The therapist could have used this opportunity to teach the child to mand, 'sea toys/toys'.",
    "startTime": 512,
    "endTime": 518,
    status: 'pending',
    "comment": []
  },
  {
    "id": 6,
    "domain": "Mand",
    "targets": "sea toys",
    "description": "The child wanted some sea toys which the therapist handed to him. The therapist could have instead used this opportunity to teach the child to mand, 'sea toys/toys'.",
    "startTime": 533,
    "endTime": 537,
    status: 'pending',
    "comment": []
  },
  {
    "id": 7,
    "domain": "Listener response",
    "targets": "touch nose",
    "description": "The therapist asked the child to touch the nose, but the child made an error by touching his cheeks. The therapist should have run an error correction trial in this situation. She should have done a distracter trial with the child followed by a DTT trial involving prompt and prompt fade.",
    "startTime": 560,
    "endTime": 568,
    status: 'pending',
    "comment": []
  },
  {
    "id": 8,
    "domain": "Mand with sign",
    "targets": "warm",
    "description": "The child wanted the therapist to touch his face. The therapist could have used this opportunity to teach the child to request the therapist for an action to touch his face using a sign or by saying, 'warm face'/'touch face'",
    "startTime": 577,
    "endTime": 579,
    status: 'pending',
    "comment": []
  },
  {
    "id": 9,
    "domain": "Mand",
    "targets": "do you want toy instead?",
    "description": "The therapist could teach the child 'yes/no' answers (verbally/sign language) to her questions when she was asking 'do you want toy instead?'. The therapist could have contrived a verbal prompt for saying 'yes/no' or physical prompt for 'yes/no' sign and prompt fade trial.",
    "startTime": 476,
    "endTime": 479,
    status: 'pending',
    "comment": []
  },
  {
    "id": 10,
    "domain": "Mand",
    "targets": "do you want more sea toys?",
    "description": "The therapist could teach the child 'yes/no' answers (verbally/sign language) to her questions when she was asking 'do you want more sea toys?'. The therapist could have contrived a verbal prompt for saying 'yes/no' or physical prompt for 'yes/no' sign and prompt fade trial.",
    "startTime": 507,
    "endTime": 509,
    status: 'pending',
    "comment": []
  },
  {
    "id": 11,
    "domain": "Mand",
    "targets": "do you want more?",
    "description": "The therapist could teach the child 'yes/no' answers (verbally/sign language) to her questions when she was asking 'do you want more?'. The therapist could have contrived a verbal prompt for saying 'yes/no' or physical prompt for 'yes/no' sign and prompt fade trial.",
    "startTime": 534,
    "endTime": 536,
    status: 'pending',
    "comment": []
  },
  {
    "id": 12,
    "domain": "Mand",
    "targets": "do you need a moment to play with your toys?",
    "description": "The therapist could teach the child 'yes/no' answers (verbally/sign language) to her questions when she was asking 'do you need a moment to play with your toys?'. The therapist could have contrived a verbal prompt for saying 'yes/no' or physical prompt for 'yes/no' sign and prompt fade trial.",
    "startTime": 173,
    "endTime": 182,
    status: 'pending',
    "comment": []
  },
  {
    "id": 13,
    "domain": "Mand",
    "targets": "do you want more sea toys?",
    "description": "The therapist could teach the child Mand for more, after she asked 'do you want more sea toys?' prompting the child to mand for more by verbally prompting for saying the word 'more' or using a physical prompt to teach sign language for the word 'more', followed by prompt fade trial.",
    "startTime": 534,
    "endTime": 536,
    status: 'pending',
    "comment": []
  },
  {
    "id": 14,
    "domain": "Mand",
    "targets": "more?, do you want more?",
    "description": "The therapist could teach the child Mand for more, after she asked 'more?, do you want more?' prompting the child to mand for more by verbally prompting for saying the word 'more' or using a physical prompt to teach sign language for the word 'more', followed by prompt fade trial.",
    "startTime": 509,
    "endTime": 518,
    status: 'pending',
    "comment": []
  },
  {
    "id": 15,
    "domain": "Mand",
    "targets": "all done",
    "description": "The therapist could teach the child Mand for all done, when she said 'all done' while showing the sign for the same, prompting the child to mand for all done by verbally prompting for saying the word 'all done' or using a physical prompt to teach sign language for the word 'all done', followed by prompt fade trial.",
    "startTime": 509,
    "endTime": 512,
    status: 'pending',
    "comment": []
  }
]
