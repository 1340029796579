import { coachableStatus, templateDrawer } from 'constants/constant'
import dayjs from 'dayjs'
import videosActions from './action'

const initialState = {
  templates: [
    {
      id: 1,
      name: 'ABA Session Note',
    },
    {
      id: 2,
      name: 'ABA Session Template',
    },
  ],
  selectedTemplate: null,
  isTemplateDrawer: false,
  tempDrawerWidth: templateDrawer.small,
  isPDFDrawer: false,
  templateData: {
    clientName: 'John Smith',
    sessionDate: dayjs(),
    participants: ['Tech', 'Client'],
    sessionTime: dayjs(),
    serviceCode: '97153',
    sessionLocation: 'Center',
    parent: 'Joane Smith',
    evidence: ['DTT', 'reinforcement', 'pairing'],
    degreeOfProgressData: [],
    sessionNarrative: `The therapist started the session with rapport building and pairing with the child. The child was motivated throughout the session. He was comfortable working with the therapist and was engaged while the therapist was running targets with him. He was receptive to instructions that were directed at him and also followed through well. He performed well on many listener responder tasks. `,
    environmentalNotes: `Keeping the reinforcements out of child's reach will be helpful, preferably on a side rack which is closer to the therapist & restricted to child. `,
    signature: null,
  },
  templateData2: {
    clientName: 'Joe lavid',
    sessionDate: dayjs(),
    participants: ['BCBA', 'Client'],
    sessionTime: dayjs(),
    serviceCode: '97',
    sessionLocation: 'Remote',
    parent: 'Joane lavid',
    evidence: ['DTT', 'reinforcement', 'pairing'],
    degreeOfProgressData: [],
    sessionNarrative: `The therapist started the session with rapport building and pairing with the child. The child was motivated throughout the session. He was comfortable working with the therapist and was engaged while the therapist was running targets with him. He was receptive to instructions that were directed at him and also followed through well. He performed well on many listener responder tasks. `,
    environmentalNotes: `Keeping the reinforcements out of child's reach will be helpful, preferably on a side rack which is closer to the therapist & restricted to child. `,
    signature: null,
  },
  participantsOption: [
    { label: 'Tech', value: 'Tech' },
    { label: 'Client', value: 'Client' },
    { label: 'BCBA', value: 'BCBA' },
    { label: 'Peer', value: 'Peer' },
    { label: 'Parent', value: 'Parent' },
    { label: 'Sibling', value: 'Sibling' },
  ],
  evidenceOption: [
    { label: 'Antecedent-Based Interventions (ABI)', value: 'ABI' },
    {
      label:
        'Differential Reinforcement of Alternative, Incompatible, or Other Behaviors, (DRA/I/O)',
      value: 'DRA',
    },
    { label: 'Prompting (PP)', value: 'PP' },
    { label: 'Discrete Trail Teaching (DTT)', value: 'DTT' },
    { label: 'Token Economy', value: 'tokenEconomy' },
    { label: 'Functional Communication Training (FCT)', value: 'FCT' },
    { label: 'Modeling (MD)', value: 'MD' },
    { label: 'Naturalistic Intervention (NI)', value: 'NI' },
    { label: 'Picture Exchange Communication System (PECS)', value: 'PECS' },
    { label: 'Pivotal Response Training (PRT)', value: 'PRT' },
    { label: 'Other:', value: 'other' },
    { label: 'Reinforcement (R+)', value: 'reinforcement' },
    { label: 'Pairing', value: 'pairing' },
  ],
}

export default function supervision(state = initialState, action) {
  switch (action.type) {
    case videosActions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
