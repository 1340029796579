import { VIDEOS } from 'constants/videos'
import SOCIAL_ORIENTATION from './action'

const initialState = {
  currentTime: 0,
  playing: false,
  totalDuration: 0,
  intervalValue: 0,
  selectedVideo: VIDEOS[4],
  predictionVideos: [VIDEOS[4], VIDEOS[1], VIDEOS[3], VIDEOS[0]],
  availableObject: [],
  selectedObject: null,
  objectInterval: 0,
}

export default function SocialOrientation(state = initialState, action) {
  switch (action.type) {
    case SOCIAL_ORIENTATION.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
