import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { REACT_APP_API_URL } from './REACT_APP_URL'
import { notification } from 'antd'

const API_END_POINT = `${REACT_APP_API_URL}/graphql`

const httpLink = new HttpLink({ uri: API_END_POINT })

const errorLink = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      notification.error({
        message,
        duration: 10,
        // description: message,
      }),
    )
  }
})

const httperrorLink = errorLink.concat(httpLink)

const authMiddleware = () =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    let authToken = ''

    if (localStorage.getItem('token') && localStorage.getItem('token') !== undefined) {
      authToken = JSON.parse(localStorage.getItem('token'))
    }
    if (authToken) {
      operation.setContext({
        headers: {
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          authorization: authToken ? `JWT ${authToken}` : '',
        },
      })
    }

    return forward(operation)
  })

const cache = new InMemoryCache({})

export const client = new ApolloClient({
  link: authMiddleware().concat(httperrorLink),
  cache,
})
