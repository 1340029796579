import dayjs from 'dayjs'
import ASSESSMENT_BUILDER from './action'

const initialState = {
  screenStep: [],
  currentStep: 0,
  totalStep: 2,
  audioUrl: '',
  defaultUrl: 'https://storage.googleapis.com/neuronahealth/ResearchDemo/sample2.mp4',
  isNextDisable: false,
  clientData: {
    clientName: 'Alex',
    caregiverName: 'Vrinda',
    diagnosis: 'Autism Spectrum Disorder',
    diagnosisDate: dayjs(),
    dateOfBirth: dayjs('11-10-2016'),
    age: 6,
    address: 'Noida Sector 20',
  },
  referralDiagnosticInfo:
    "Reason for Referral : Parent expressed concerns about child's inability to communicate effectively with peers, difficulty in initiating conversations, and preferring to speak in length about his own interests without listening to others. Diagnostic Information : Child is more comfortable speaking in English, has a weak physical appearance despite being 6 years old, has a history of severe ear infections, underwent speech therapy The child can speak in sentences but struggles with initiating conversations, asking questions, and expressing himself in detail. He is sensitive to his mood and environment, and may throw objects or close his ears when upset.",
  availability:
    'The parent mentioned they are available for 2 hours daily, with a preference for late afternoons or early evenings. Home sessions are preferred due to the long travel distance.',
  backgroundInfo: {
    languageSpoken: 'English, Hindi',
    medicationPrescribed: 'None Mentioned',
    otherHealthConcerns: 'Stomach issue',
    individualsLiveInHome: 'Mother and Elder brother, Father lives in UK',
    allergiesOfDietryRestrictions: 'No',
    chromicCondition: 'No',
    otherMedicalDiagnosis: 'Ear infection',
    familyHistoryOfDiagnosis: 'Cousin sister in UK had some issues in walking',
    prevSurgeryProcedures: 'No',
  },
  developmentalHistory: {
    birthHistory: 'No complications during pregnancy or birth. Alex was a very chubby baby.',
    earlyMilestones:
      'Alex was very energetic even as a baby, He used to make a lot of sounds but he was not happy to see many people around him',
    languageDevelopment: ' Alex had could not talk too and fro till 2.5 years.',
    socialDevelopment: 'Alex has some gaps to express',
    motorSkills: 'Alex can walk and jump around.',
    sensoryProcessing: 'It depends on his moods.',
    regulatorySkills: 'Alex struggles expressing, he starts throwing things around',
  },
  educationHistory: {
    schoolName: 'International Public School',
    IEPBIPInSchool: 'No',
    gradeEducationSetting: 'Grade & Educational Setting',
    therapeuticServices:
      'They have a combination of behavior, speech, and other therapies, transitioning to group therapy thrice weekly for 2-3 hours',
  },
  otherHistory: [
    {
      therapyService: 'PT',
      hoursOfService: 'Not Consistent',
      serviceProvider: 'Individual Providers',
      dateOfService: 'Not Provided',
    },
  ],
  preferrenceAssessment: {
    preferredItems: 'Loves drawing and coloring, Alex is obssessed with Spider-Man',
    knownAversions: 'Does not like to study',
  },
  BIPlanData: {
    heading: 'Target Behavior #1: Screaming',
    operationalDef:
      'Screaming is defined as any instance in which Alex engages in vocalizations that are louder than what is appropriate for the given situation',
    hypothesizedFuncBased:
      'It is hypothesized that the function of the screaming behavior is automatic (sensory) and access',
    antecedentInterventions: [
      'Visual Schedule-Alex should utilize a visual schedule throughout his day in order to outline expectations',
      'Token Board-Alex should utilize a token board throughout session',
      'Premack Principle-Premack principle should be used when necessary throughout Alex’s day',
      'Alex should have access to sensory stimulating items freely throughout his day',
    ],
    deEscalationStrategies: [
      'Differential Reinforcement of an alternative behavior-Alex should receive reinforcement when engaging in an alternative behavior (e.g., mands for desired items.)',
      'Prompt Alex to use functional communication skills when applicable',
    ],
    replacementBehaviors: [
      'Alex will utilize a sensory stimulating item or engage in a sensory stimulating activity as needed',
    ],
  },
  behReductionGoals: {
    goal: 'Screaming - Alex will reduce episodes of screaming by 50% of baseline',
    masterCriteria: '50% of baseline across 10 consecutive sessions',
    baselineDate: dayjs(),
    baselineData:
      '203x/week per parent report. Clinical baseline will be taken at the onset of services',
    targetedCompletionDate: dayjs().add(120, 'day'),
  },
  replacementBehaviorGoals: {
    goal: 'Manding for Desired Items - Alex will mand for 10 different reinforcers via vocal language ASL PECS or similar',
    masterCriteria: '80% of opportunities across 5 consecutive sessions',
    baselineData: '0% of opportunities. Alex does not currently mand for desired items',
    baselineDate: dayjs(),
    targetedCompletionDate: dayjs().add(120, 'day'),
  },
  skillAcquisitionGoal: {
    communicationGoals: {
      notes:
        '- During the Communication Domain assessment of the Vineland Adaptive Behaviour Scales for Alex, age 6, several deficits were identified. He demonstrated difficulty with understanding and following instructions, had a limited vocabulary, and struggled to read and write. These issues could impact his academic progress and daily communication with peers and adults.',
      data: [
        {
          goal: "Alex, age 6, will improve his receptive language skills by understanding and following instructions with multiple steps. For example, 'Take your toy, go to the table, and put it away.', technician can provide clear instructions, use gestures, and guide him through each step. Regular repetition and reinforcement will be crucial for progress. Alex will enhance his expressive language abilities by asking 'why' questions as part of his daily communication. For instance, 'Why is the cat sleeping?', the technician can pose similar questions during play and daily activities, model the correct usage, and provide positive feedback when he asks 'why' questions. Alex will advance his written communication skills by practicing writing his name, simple words, and sentences. Technician can provide him with writing practice sheets, write words for Alex to imitate, and encourage frequent writing in activities like journaling or storytelling. Alex will develop his understanding of written language by reading age-appropriate books, and playing word games. Technician can provide reading materials, engage Alex in word games, and model reading aloud to encourage his interest and skills. Alex will expand his receptive language skills by recognizing and understanding a variety of facial expressions and nonverbal cues. Technician can display pictures of facial expressions and discuss their meaning, mimic expressions during conversations, and role-play scenarios to help Alex learn the correct interpretation of these expressions",
          masterCriteria: '80% of opportunities across 5 consecutive sessions',
          baselineDate: dayjs(),
          baselineData: '0% of opportunities. Alex does not currently mand for desired items',
          targetedCompletionDate: dayjs().add(120, 'day'),
        },
      ],
    },
    socialGoals: {
      notes:
        '- Alex, age 6, exhibited a significant lack of skills in socialization, particularly in the subdomains of Interpersonal Relationships and Play and Leisure. He scored zero on most questions, indicating a need for improvement in forming and maintaining connections, empathy, social responsiveness, engaging in appropriate activities alone or with others, following game rules, and using leisure for satisfaction.',
      data: [
        {
          goal: "Alex will learn to show interest in peers by engaging in a peer play activity for 10 minutes daily. The technician can facilitate this by setting up a playdate with a neighboring child and providing materials for a collaborative game, such as building blocks. Alex will improve his emotional regulation skills by practicing coping strategies during transitions. The technician can help him develop these skills by using visual schedules, giving verbal reminders about upcoming transitions, and counting down from five to signal the end of an activity. Alex will enhance his social responsiveness by practicing empathy and expressing emotions appropriately. The technician can guide him by sharing stories about feelings and helping him identify emotions in pictures or cartoons. Alex can practice responding with empathy phrases like, 'I understand how you feel,' or 'That sounds tough'. Alex will develop coping skills by learning to ask for help and expressing needs in an appropriate manner. The technician can create opportunities for Alex to practice asking for help with tasks like building a tower using blocks or setting the table for meals. Alex will strengthen his interpersonal relationships by practicing initiating interactions with peers. The technician can provide opportunities for Alex to start conversations with peers, such as asking about their favorite toys or what they did during the day.",
          masterCriteria: '80% of opportunities across 5 consecutive sessions',
          baselineDate: dayjs(),
          baselineData: '0% of opportunities. Alex does not currently mand for desired items',
          targetedCompletionDate: dayjs().add(120, 'day'),
        },
      ],
    },
    funcLivingSkillGoals: {
      notes:
        '-Alex showed significant deficits in various daily living skills, including covering his mouth when coughing or sneezing, understanding and following car passenger safety rules, using good manners while eating in public, identifying days of the week correctly, and dressing independently. These areas require targeted interventions to help Alex develop essential skills for self-care, home living, and community participation.',
      data: [
        {
          goal: "Should learn to cover his mouth and nose when coughing or sneezing, To achieve this goal, a technician can simulate situations that require Alex to practice covering his mouth and nose, such as playing a pretend game of 'catching germs' using a handkerchief or a tissue. Should learn to understand and follow car passenger safety,technician could employ the use of visual aids, such as diagrams or pictures, to clearly explain the safety rules. Should learn to put on simple pullover garments independently, technician could guide him step-by-step through the process of putting on a pullover garment, breaking down each step into simple, manageable tasks. Alex needs to learn to use good manners when eating in public, technician could practice eating in a mock restaurant setting, where Alex can learn to wait for his turn to eat, use utensils properly, and say 'please' and 'thank you' to the'server'. Should learn to correctly identify the seven days of the week in order, technician could introduce this goal by using interactive methods, such as songs, games, or storybooks, that make learning the days of the week enjoyable.",
          masterCriteria: '80% of opportunities across 5 consecutive sessions',
          baselineDate: dayjs(),
          baselineData: '0% of opportunities. Alex does not currently mand for desired items',
          targetedCompletionDate: dayjs().add(120, 'day'),
        },
      ],
    },
  },
  PCInvolvementData: {
    notes:
      "- Alex's parents understand the importance of participating in ABA therapy and attending parent meetings with the BCBA care supervisor. The following goals will be targeted and reviewed with the parents.",
    data: [
      {
        goal: "Accurate Implementation - Alex's parents will accurately implement his behavior intervention plan (BIP)",
        masterCriteria: '80% of opportunities across 5 consecutive sessions',
        baselineDate: dayjs(),
        baselineData: '0% of opportunities. Alex does not currently mand for desired items',
        targetedCompletionDate: dayjs().add(120, 'day'),
      },
      {
        goal: 'Attends Parent Training - To increase the effectiveness of therapy when practiced at home the caregiver will attend monthly meetings requested by BCBA.',
        masterCriteria: '80% of opportunities across 5 consecutive sessions',
        baselineDate: dayjs(),
        baselineData: '0% of opportunities. Alex does not currently mand for desired items',
        targetedCompletionDate: dayjs().add(120, 'day'),
      },
    ],
  },
  vinelandAssessData: {
    'Communication Domain': [
      {
        SubDomain: 'Expressive Subdomain',
        QuestionCount: 6,
        FinalScore: 2,
      },
      {
        SubDomain: 'Receptive Subdomain',
        QuestionCount: 9,
        FinalScore: 11,
      },
      { SubDomain: 'Written Subdomain', QuestionCount: 4, FinalScore: 0 },
    ],
    'Daily Living Skills Domain': [
      {
        SubDomain: 'Community Subdomain',
        QuestionCount: 4,
        FinalScore: 0,
      },
      { SubDomain: 'Personal Subdomain', QuestionCount: 5, FinalScore: 7 },
    ],
    'Socialization Domain': [
      {
        SubDomain: 'Coping Skills Subdomain',
        QuestionCount: 7,
        FinalScore: 4,
      },
      {
        SubDomain: 'Interpersonal Relationships Subdomain',
        QuestionCount: 15,
        FinalScore: 14,
      },
      {
        SubDomain: 'Play and Leisure Subdomain',
        QuestionCount: 6,
        FinalScore: 5,
      },
    ],
  },
}

export default function AssessmentBuilder(state = initialState, action) {
  switch (action.type) {
    case ASSESSMENT_BUILDER.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
