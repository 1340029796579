import { notification } from 'antd'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import videosActions from './action'
import {
  
} from './services'

export function* LOAD_DEFAULTS({ payload }) {
  yield put({
    type: videosActions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  yield put({
    type: videosActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(videosActions.LOAD_DEFAULTS, LOAD_DEFAULTS),
  ])
}
