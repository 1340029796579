import { videosDomainData } from './domainContent'
import { srt1, srt2, srt3, srt4, srt5, srt6, srt7 } from './transcriptContent'

import video1GazeJson from './dataFiles/GazeVideoJSON/ABA_Techniques_BY_BCBA/gaze_output.json'
import video1headPoseJson from './dataFiles/GazeVideoJSON/ABA_Techniques_BY_BCBA/head_pose_output.json'
import video1ObjectIdentificationJson from './dataFiles/GazeVideoJSON/ABA_Techniques_BY_BCBA/objectIdentification.json'

import video2GazeJson from './dataFiles/GazeVideoJSON/ABA_Therapy-Deniel-Communication/gaze_output.json'
import video2headPoseJson from './dataFiles/GazeVideoJSON/ABA_Therapy-Deniel-Communication/head_pose_output.json'
import video2ObjectIdentificationJson from './dataFiles/GazeVideoJSON/ABA_Therapy-Deniel-Communication/objectIdentification.json'

import video4GazeJson from './dataFiles/GazeVideoJSON/DescriteTrialTeaching(DTT)/gaze_output.json'
import video4headPoseJson from './dataFiles/GazeVideoJSON/DescriteTrialTeaching(DTT)/head_pose_output.json'
import video4ObjectIdentificationJson from './dataFiles/GazeVideoJSON/DescriteTrialTeaching(DTT)/objectIdentification.json'

import video5GazeJson from './dataFiles/GazeVideoJSON/NaturalEnvironmentTeaching/gaze_output.json'
import video5headPoseJson from './dataFiles/GazeVideoJSON/NaturalEnvironmentTeaching/head_pose_output.json'
import video5ObjectIdentificationJson from './dataFiles/GazeVideoJSON/NaturalEnvironmentTeaching/objectIdentification.json'

import sessAnalyVideo1 from './dataFiles/SessionAnalysisJSON/ABA_TechniquesBYBCBA.json'
import sessAnalyVideo4 from './dataFiles/SessionAnalysisJSON/DiscreteTrialTeaching(DTT).json'
import sessAnalyVideo5 from './dataFiles/SessionAnalysisJSON/NaturalEnvironmentTeaching.json'
import sessAnalyVideo7 from './dataFiles/SessionAnalysisJSON/AutisticBoyHavingMeltDown.json'

export const VIDEOS = [
  {
    id: 1,
    name: 'Assessment 1',
    defaultUrl:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/ABA%20(Applied%20Behavior%20Analysis)%20Techniques%20by%20BCBA.mp4',
    bbox_url:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/videos_with_bbox/ABA%20(Applied%20Behavior%20Analysis)%20Techniques%20by%20BCBA.mp4',
    gaze_url:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/Predictions/ABA%20(Applied%20Behavior%20Analysis)%20Techniques%20by%20BCBA/Gaze_ABA%20(Applied%20Behavior%20Analysis)%20Techniques%20by%20BCBA.mp4',
    transcript_with: srt1.with_speaker,
    transcript: srt1.without_speaker,
    thubnail: 'images/video1.png',
    domainContent: videosDomainData.video1,
    gazeJsonData: {
      gazeOutput: video1GazeJson,
      headPoseOutput: video1headPoseJson,
      objectIdentification: video1ObjectIdentificationJson,
    },
    sessionAnalysisJSON: sessAnalyVideo1,
    sessionSummary: `During this ABA therapy session, the therapist focused on the domain of Receptive Communication to engage and teach the child. The child was given simple gross motor instructions to interact with a banana, such as touching, showing, and even catching it. The therapist used the stimulus of the banana to prompt the child's responses, which were all independent and correct. The goal of this interaction was to improve the child's ability to follow instructions and understand simple commands. Overall, the session was successful in enhancing the child's receptive communication skills through the use of the banana as a teaching tool.`,
    duration: '00:46 min',
  },
  {
    id: 2,
    name: 'Assessment 2',
    defaultUrl:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/ABA%20Therapy_%20Daniel%20-%20Communication.mp4',
    bbox_url:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/videos_with_bbox/ABA%20Therapy_%20Daniel%20-%20Communication.mp4',
    gaze_url:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/Predictions/ABA%20Therapy_%20Daniel%20-%20Communication/Gaze_ABA%20Therapy_%20Daniel%20-%20Communication.mp4',
    transcript_with: srt2.with_speaker,
    transcript: srt2.without_speaker,
    thubnail: 'images/video2.png',
    domainContent: videosDomainData.video2,
    gazeJsonData: {
      gazeOutput: video2GazeJson,
      headPoseOutput: video2headPoseJson,
      objectIdentification: video2ObjectIdentificationJson,
    },
    sessionSummary: `During this ABA therapy session, the therapist and child engaged in an interaction focused on the Mand (Manding) domain. The therapist prompted the child to identify and request objects needed to play a game, such as the lid and the popcorn. The child successfully responded by retrieving the requested items. The therapist also provided positive reinforcement for the child's correct responses and good sitting behavior. Other stimuli used in the session included a teddy bear and a cup. The overall goal of the interaction was to improve the child's ability to understand and request objects in a socially acceptable way.`,
    sessionAnalysisJSON: null,
    duration: '02:08 min',
  },
  {
    id: 3,
    name: 'Assessment 3',
    defaultUrl:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/Autistic%2019%20year%20old%20has%20a%20meltdown%20biting%20his%20dads%20hand.mp4',
    bbox_url: '',
    gaze_url: '',
    transcript_with: srt3.with_speaker,
    transcript: srt3.without_speaker,
    thubnail: 'images/video3.png',
    domainContent: videosDomainData.video3,
    gazeJsonData: {
      gazeOutput: null,
      headPoseOutput: null,
      objectIdentification: null,
    },
    sessionAnalysisJSON: null,
    sessionSummary: `During this ABA therapy session, the therapist focused on addressing the child's maladaptive behaviors, specifically self-injurious and tantrum behaviors. The child exhibited these behaviors through hitting himself on the head and crying. The therapist provided verbal reassurance and redirection, encouraging the child to look at her and take deep breaths. No specific domains were used during these instances of maladaptive behavior. The goal was to help the child regulate his emotions and reduce the frequency of these behaviors.`,
    duration: '00:00 min',
  },
  {
    id: 4,
    name: 'Assessment 4',
    defaultUrl:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/Discrete%20Trial%20Teaching%20(DTT).mp4',
    bbox_url:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/videos_with_bbox/Discrete%20Trial%20Teaching%20(DTT).mp4',
    gaze_url:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/Predictions/DiscreteTrialTeachingDTT/Gaze_DiscreteTrialTeachingDTT.mp4',
    transcript_with: srt4.with_speaker,
    transcript: srt4.without_speaker,
    thubnail: 'images/video4.png',
    domainContent: videosDomainData.video4,
    gazeJsonData: {
      gazeOutput: video4GazeJson,
      headPoseOutput: video4headPoseJson,
      objectIdentification: video4ObjectIdentificationJson,
    },
    sessionAnalysisJSON: sessAnalyVideo4,
    sessionSummary: `During the ABA therapy session, the therapist focused on the domains of Tact (labeling colors) and Mand (requesting). The therapist used errorless teaching trials and echoic prompts to help the child correctly label various colors, such as yellow, red, and blue. The child demonstrated progress in this area, responding correctly and independently to the therapist's questions. Additionally, the child spontaneously requested information from the therapist and correctly requested a desired item, demonstrating understanding of the Mand domain. The therapist provided positive reinforcement throughout the session, using verbal praise and gestures like "wooing" to encourage the child's correct responses.`,
    duration: '02:08 min',
  },
  {
    id: 5,
    name: 'Assessment 5',
    defaultUrl:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/Natural%20Environment%20Teaching%20NET.mp4',
    bbox_url:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/videos_with_bbox/Natural%20Environment%20Teaching%20NET.mp4',
    gaze_url:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/Predictions/Natural%20Environment%20Teaching%20NET/Gaze_Natural%20Environment%20Teaching%20NET.mp4',
    transcript_with: srt5.with_speaker,
    transcript: srt5.without_speaker,
    thubnail: 'images/video5.png',
    domainContent: videosDomainData.video5,
    gazeJsonData: {
      gazeOutput: video5GazeJson,
      headPoseOutput: video5headPoseJson,
      objectIdentification: video5ObjectIdentificationJson,
    },
    sessionAnalysisJSON: sessAnalyVideo5,
    sessionSummary: `During the ABA therapy session, the child and therapist engaged in various interactions focused on the domains of Tact (labeling objects and identifying colors) and Intraverbal (answering wh-questions). The therapist used a combination of verbal and echoic prompts to help the child accurately label objects and answer questions. For instance, the child was able to correctly label a fire truck as "red" and identify a stop sign as a "stop sign." The therapist also asked open-ended questions, such as "What else flies in the sky?" and "Tell me an animal that flies in the sky," to which the child provided responses with guidance from the therapist. The session concluded with the therapist asking the child to identify various parts of a school bus, such as wheels, door, and seats. Overall, the therapist effectively used a variety of stimuli, including toys and objects, to facilitate the child's learning and development in the targeted domains.`,
    duration: '02:49 min',
  },
  {
    id: 6,
    name: 'Assessment 6',
    defaultUrl:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/Paired%20Stimuli_Forced%20Choice%20Preference%20Assessment.mp4',
    bbox_url:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/videos_with_bbox/Paired%20Stimuli_Forced%20Choice%20Preference%20Assessment.mp4',
    gaze_url: '',
    transcript_with: srt6.with_speaker,
    transcript: srt6.without_speaker,
    thubnail: 'images/video6.png',
    domainContent: videosDomainData.video6,
    gazeJsonData: {
      gazeOutput: null,
      headPoseOutput: null,
      objectIdentification: null,
    },
    sessionSummary: '',
    sessionAnalysisJSON: null,
    duration: '00:00 min',
  },
  {
    id: 7,
    name: 'Assessment 7',
    defaultUrl:
      'https://storage.googleapis.com/neuronahealth/ResearchDemo/April_demo/Part%202%20Severely%20Autistic%20Boy%20Having%20a%20Meltdown.mp4',
    bbox_url: '',
    gaze_url: '',
    transcript_with: srt7.with_speaker,
    transcript: srt7.without_speaker,
    thubnail: 'images/video7.png',
    domainContent: videosDomainData.video7,
    gazeJsonData: {
      gazeOutput: null,
      headPoseOutput: null,
      objectIdentification: null,
    },
    sessionAnalysisJSON: sessAnalyVideo7,
    sessionSummary: '',
    duration: '02:12 min',
  },
]
