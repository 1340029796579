import { notification } from 'antd'
import { coachableStatus } from 'constants/constant'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import SUPERVISION from './action'

import {} from './services'

function* LOAD_CLIPS({ payload }) {
  yield put({
    type: SUPERVISION.SET_STATE,
    payload: {
      loading: true,
    },
  })

  yield put({
    type: SUPERVISION.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

function* SAVE_COMMENT({ payload }) {
  const { coachableClips, selectedClip } = yield select(state => state.supervision)
  notification.success({
    message: 'Comment saved successfully!',
    duration: 1,
  })
  yield put({
    type: SUPERVISION.SET_STATE,
    payload: {
      coachableClips: coachableClips.map(item => {
        if (item.id === payload.id) {
          return {
            ...item,
            comment: [payload.comment, ...item.comment],
            status: coachableStatus.completed,
          }
        }
        return item
      }),
      selectedClip: {
        ...selectedClip,
        comment: [payload.comment, ...selectedClip.comment],
      },
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(SUPERVISION.LOAD_CLIPS, LOAD_CLIPS),
    takeLatest(SUPERVISION.SAVE_COMMENT, SAVE_COMMENT),
  ])
}
