import { notification } from 'antd'
import { coachableStatus } from 'constants/constant'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import TEMPLATES from './action'

import {} from './services'

function* LOAD_TEMPLATES({ payload }) {
  yield put({
    type: TEMPLATES.SET_STATE,
    payload: {
      loading: true,
    },
  })

  yield put({
    type: TEMPLATES.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeLatest(TEMPLATES.LOAD_TEMPLATES, LOAD_TEMPLATES)])
}
