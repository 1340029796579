import { all } from 'redux-saga/effects'
import user from './user/saga'
import setting from './settings/sagas'
import videoAndTranscript from './videoAndTranscript/saga'
import supervision from './Supervision/saga'
import templates from './Templates/saga'
import sessionAnalysis from './SessionAnalysis/saga'
import socialOrientation from './SocialOrientation/saga'
import assessmentBuilder from './AssessmentBuilder/saga'

export default function* rootSaga() {
  yield all([
    user(),
    setting(),
    videoAndTranscript(),
    supervision(),
    templates(),
    sessionAnalysis(),
    socialOrientation(),
    assessmentBuilder(),
  ])
}
