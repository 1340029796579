export const status = [
  {
    id: 'pending',
    label: 'Pending',
  },
  {
    id: 'completed',
    label: 'Completed',
  },
  {
    id: 'cancelled',
    label: 'Cancelled',
  },
]

export const meetingStatus = [
  {
    id: 'pending',
    label: 'Pending',
  },
  {
    id: 'approved',
    label: 'Approved',
  },
  {
    id: 'cancelled',
    label: 'Cancelled',
  },
]

export const coachableStatus = {
  pending: 'pending',
  completed: 'completed',
}

export const templateDrawer = {
  small: '30%',
  large: '80%',
}
