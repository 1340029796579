import { VIDEOS } from 'constants/videos'
import SESSION_ANALYSIS from './action'

const initialState = {
  videos: [VIDEOS[3], VIDEOS[6], VIDEOS[0], VIDEOS[4]],
  selectedVideo: VIDEOS[3],
  clipChangeCounter: 0,
  selectedClass: {
    className: 'Tact',
    status: 'checked',
    subclass: [
      {
        subClassName: 'Labelling Nouns',
        stimulusNames: [
          {
            stimulusName: 'Yellow',
            data: [
              {
                startTime: 0,
                endTime: 4,
                summary:
                  'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child incorrectly responds green',
              },
              {
                startTime: 9,
                endTime: 12,
                summary:
                  'The therapist is doing an errorless teaching trial to teach the kid to label the yellow item correctly by presenting the SD and the stimulus first, and giving an echoic prompt yellow. The child repeats the word.',
              },
              {
                startTime: 12,
                endTime: 16,
                summary:
                  'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds yellow independently.',
              },
              {
                startTime: 81,
                endTime: 83,
                summary:
                  'The therapist is doing an errorless teaching trial to teach the kid to label the yellow item correctly by presenting the SD and the stimulus first, and giving an echoic prompt yellow. The child repeats the word.',
              },
              {
                startTime: 83,
                endTime: 87,
                summary:
                  'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds yellow independently.',
              },
            ],
          },
          {
            stimulusName: 'Red',
            data: [
              {
                startTime: 31,
                endTime: 36,
                summary:
                  'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds red independently.',
              },
              {
                startTime: 73,
                endTime: 76,
                summary:
                  'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds red independently.',
              },
              {
                startTime: 120,
                endTime: 122,
                summary:
                  'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds “red” independently.',
              },
              {
                startTime: 122,
                endTime: 125,
                summary:
                  'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds “green” independently. The therapist provides reinforcement in the form of wooing.',
              },
            ],
          },
          {
            stimulusName: 'Green',
            data: [
              {
                startTime: 70,
                endTime: 73,
                summary:
                  'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds green independently.',
              },
            ],
          },
          {
            stimulusName: 'Blue',
            data: [
              {
                startTime: 76,
                endTime: 78,
                summary:
                  'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds blue independently.',
              },
              {
                startTime: 117,
                endTime: 120,
                summary:
                  'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds “blue” independently.',
              },
            ],
          },
        ],
      },
    ],
  },
  selectedSubClass: {
    subClassName: 'Labelling Nouns',
    stimulusNames: [
      {
        stimulusName: 'Yellow',
        data: [
          {
            startTime: 0,
            endTime: 4,
            summary:
              'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child incorrectly responds green',
          },
          {
            startTime: 9,
            endTime: 12,
            summary:
              'The therapist is doing an errorless teaching trial to teach the kid to label the yellow item correctly by presenting the SD and the stimulus first, and giving an echoic prompt yellow. The child repeats the word.',
          },
          {
            startTime: 12,
            endTime: 16,
            summary:
              'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds yellow independently.',
          },
          {
            startTime: 81,
            endTime: 83,
            summary:
              'The therapist is doing an errorless teaching trial to teach the kid to label the yellow item correctly by presenting the SD and the stimulus first, and giving an echoic prompt yellow. The child repeats the word.',
          },
          {
            startTime: 83,
            endTime: 87,
            summary:
              'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds yellow independently.',
          },
        ],
      },
      {
        stimulusName: 'Red',
        data: [
          {
            startTime: 31,
            endTime: 36,
            summary:
              'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds red independently.',
          },
          {
            startTime: 73,
            endTime: 76,
            summary:
              'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds red independently.',
          },
          {
            startTime: 120,
            endTime: 122,
            summary:
              'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds “red” independently.',
          },
          {
            startTime: 122,
            endTime: 125,
            summary:
              'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds “green” independently. The therapist provides reinforcement in the form of wooing.',
          },
        ],
      },
      {
        stimulusName: 'Green',
        data: [
          {
            startTime: 70,
            endTime: 73,
            summary:
              'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds green independently.',
          },
        ],
      },
      {
        stimulusName: 'Blue',
        data: [
          {
            startTime: 76,
            endTime: 78,
            summary:
              'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds blue independently.',
          },
          {
            startTime: 117,
            endTime: 120,
            summary:
              'The therapist shows a card to the child and asks What colour? to label the colour for tact. The child correctly responds “blue” independently.',
          },
        ],
      },
    ],
  },
  videoTotalDuration: 0,
  selectedClip: null,
  activeLabels: [],
  currentTime: 0,
}

export default function sessionAnalysis(state = initialState, action) {
  switch (action.type) {
    case SESSION_ANALYSIS.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
