import { coachableStatus } from 'constants/constant'
import dayjs from 'dayjs'
import SUPERVISION from './action'
import { data, data2 } from './data'

const initialState = {
  selectedClip: null,
  url: 'https://storage.googleapis.com/neuronahealth/ResearchDemo/coverted-sample.m4v',
  selectedSession: 1,
  videoClips: data,
  coachableClips: data2,
  sessionList: [
    {
      id: 1,
      name: 'XYZ',
      tech: 'John Smith',
      client: 'Johny Jones',
      date: dayjs().format('YYYY-MM-DD'),
    },
    {
      id: 2,
      name: 'ABC',
      tech: 'Jane Doe',
      client: 'Jane Smith',
      date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    },
    {
      id: 3,
      name: 'PQR',
      tech: 'Bob Johnson',
      client: 'Bob Brown',
      date: dayjs().subtract(2, 'days').format('YYYY-MM-DD'),
    },
    {
      id: 4,
      name: 'LMN',
      tech: 'Alice Williams',
      client: 'Alice Davis',
      date: dayjs().subtract(3, 'days').format('YYYY-MM-DD'),
    },
    {
      id: 5,
      name: 'EFG',
      tech: 'Sam Wilson',
      client: 'Sammy White',
      date: dayjs().subtract(4, 'days').format('YYYY-MM-DD'),
    },
  ],
  selectedComment: null,
}

export default function supervision(state = initialState, action) {
  switch (action.type) {
    case SUPERVISION.SET_STATE:
      return { ...state, ...action.payload }
    case SUPERVISION.DELETE_COMMENT:
      return {
        ...state,
        coachableClips: state.coachableClips.map(item => {
          if (item.id === state.selectedClip.id) {
            return {
              ...item,
              comment: item.comment.filter(cmt => cmt.id !== action.payload.id),
            }
          }
          return item
        }),
        selectedClip: {
          ...selectedClip,
          comment: state.selectedClip.comment.filter(cmt => cmt.id !== action.payload.id),
        },
      }
    default:
      return state
  }
}
