import videosActions from './action'
import {VIDEOS} from '../../constants/videos' 

const initialState = {
  loading: false,
  screenStep: 0,
  totalStep: 4,
  isNextDisable: true,
  videoUrl: null,
  defaultUrl: 'https://storage.googleapis.com/neuronahealth/ResearchDemo/sample2.mp4',
  selectedVideoObject: null,

  videoObject1: VIDEOS[0],
  videoObject2: VIDEOS[1],
  videoObject3: VIDEOS[3],
  videoObject4: VIDEOS[4],
  videoObject5: VIDEOS[5],
}

export default function videosActionsReducer(state = initialState, action) {
  switch (action.type) {
    case videosActions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
