import React from 'react'
import { createHashRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import LoadingComponent from './components/LoadingComponent'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <LoadingComponent />,
  })

const routes = createHashRouter([
  // Annotation test routes
  {
    path: '/',
    Component: loadable(() => import('Pages/user')),
    caseSensitive: true,
    end: true,
  },
  {
    path: '/user/signUp',
    Component: loadable(() => import('Pages/user/signUp')),
  },
  {
    path: '/user/forgotPassword',
    Component: loadable(() => import('Pages/user/forgotPassword')),
  },
  {
    path: '/user/otp',
    Component: loadable(() => import('Pages/user/forgotPassword/OtpVerification')),
  },
  {
    path: '/user/changePassword',
    Component: loadable(() => import('Pages/user/forgotPassword/ChangePassword')),
  },
  {
    path: 'deactivate-account/:deleteId',
    Component: loadable(() => import('Pages/user/DeactivateAccount')),
  },
  {
    path: '/dashboard/bcba',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/bcbaDashboard')),
  },
  {
    path: '/userProfile',
    Component: loadable(() => import('Pages/user/UserProfile')),
  },
  {
    path: '/ABASessionNote',
    Component: loadable(() => import('Pages/ABASessionNote/Templates')),
  },
  {
    path: '/Template/:templateID',
    Component: loadable(() => import('Pages/ABASessionNote/LoadTemplate')),
  },
  {
    path: '/video/processing',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/VideoAndTranscript')),
  },
  {
    path: '/select-video',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/VideoAndTranscript/VideoSelection')),
  },
  {
    path: '/supervision',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/Supervision')),
  },
  {
    path: '/parent-session',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/SessionSummary')),
  },
  {
    path: '/sessionAnalysis',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/SessionAnalysis')),
  },
  {
    path: '/socialOrientation',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/SocialOrientation')),
  },
  {
    path: '/audio/processing',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/AssessmentBuilder/VideoScreen')),
  },
  {
    path: '/assessmentBuilder',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/AssessmentBuilder/Assessment')),
  },
])

export default routes
