/* eslint-disable no-unused-vars */

import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

export async function login(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation TokenAuth($username: String!, $password: String!) {
          tokenAuth(input: { username: $username, password: $password }) {
            isVerified
            firstLogin

            user {
              id
              lastLogin
              isSuperuser
              chatuser {
                id
              }
              groups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              staff {
                id
                meetLink
                email
                firstname
                lastname
                contactNo
                gender
                dob
                address
                isActive
                languages {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                designation
                education
                experience
                city
                state
                country {
                  id
                  name
                }

                image
              }
              students {
                id
                clinic {
                  id
                  schoolName
                }
                country {
                  id
                  name
                }
                firstname
                lastname
                parentFirstname
                parentLastname
                parentNickname
                email
                gender
                pronounceChild
                dob
                zipCode
                currentAddress
                mobileno
                image
                learnerassessmentsSet {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
              username
              firstName
              lastName
              email
              school {
                id
                schoolName
                email
                address
                contactNo
                logo
                isDefault
                studentsSet {
                  edges {
                    node {
                      id
                      firstname
                      lastname
                      mobileno
                      currentAddress
                      zipCode
                      email
                      gender
                      dob
                    }
                  }
                }
                clinictutorialvideosSet {
                  edges {
                    node {
                      id
                      clinic {
                        id
                        schoolName
                      }
                      tutorialVideos {
                        id
                        video {
                          id
                          videoName
                          videoUrl
                        }
                        isActive
                        module {
                          id
                          name
                          isActive
                        }
                        subModule {
                          id
                          name
                          isActive
                        }
                      }
                    }
                  }
                }
              }
            }
            countryName
            firstLogin
            token
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        username: payload.username.trim(),
        password: payload.password,
      },
    })
    .then(data => {
      localStorage.setItem('token', JSON.stringify(data?.data.tokenAuth.token))
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'login',
        userId: data?.data.tokenAuth.user.id,
      })
      if (data?.data.tokenAuth.user) {
        localStorage.setItem('userName', JSON.stringify(data?.data.tokenAuth.user.email))
        localStorage.setItem('userId', JSON.stringify(data?.data.tokenAuth.user.id))
      }
      if (data?.data.tokenAuth.user.groups.edges.length > 0) {
        localStorage.setItem(
          'role',
          JSON.stringify(data?.data.tokenAuth.user.groups.edges[0]?.node.name.toLowerCase()),
        )
      }
      //   localStorage.setItem('database', JSON.stringify('india'))
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function signUp(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation parentSignUp(
          $email: String!
          $firstname: String!
          $lastname: String
          $dob: Date!
          $gender: String
          $other: String
          $country: ID!
          $parentFirstname: String
          $parentLastname: String
          $zipCode: String
          $password: String
        ) {
          studentSignup(
            input: {
              studentData: {
                email: $email
                firstname: $firstname
                lastname: $lastname
                dob: $dob
                zipCode: $zipCode
                gender: $gender
                pronounceChild: $other
                country: $country
                password: $password
                parentFirstname: $parentFirstname
                parentLastname: $parentLastname
              }
            }
          ) {
            student {
              id
              firstname
              lastname
              zipCode
              parentFirstname
              parentLastname
              email
              country {
                id
                name
              }
              parent {
                id
                username
              }
              email
            }
            message
            status
            __typename
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function sendOtp(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation sendOtpForAccountVerfication($userID: ID!) {
          sendOtpForAccountVerfication(input: { user: $userID }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(res => {
      return res?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function verifyOtp(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation accountVerificationWithOtp($userID: ID!, $otp: String!) {
          accountVerificationWithOtp(input: { user: $userID, otp: $otp }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(res => {
      return res?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function forgetPassword(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation forgotPassword($username: String!) {
          forgotPassword(input: { username: $username }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(res => {
      return res?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function verifyChangePassword(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation verifyChangePassword($username: String!, $otp: String!, $newPassword: String!) {
          verifyChangePassword(
            input: { username: $username, otp: $otp, newPassword: $newPassword }
          ) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(res => {
      return res?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function changeUserPassword(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation changeUserPassword($current: String!, $newPassword: String!) {
          changeUserPassword(input: { current: $current, new: $newPassword }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(res => {
      return res?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getUserDetails() {
  return client
    .query({
      query: gql`
        query userDetails {
          userDetails {
            id
            lastLogin
            isSuperuser
            chatuser {
              id
            }
            groups {
              edges {
                node {
                  id
                  name
                }
              }
            }
            staff {
              id
              meetLink
              email
              firstname
              lastname
              contactNo
              gender
              dob
              address
              isActive
              languages {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              designation
              education
              experience
              city
              state
              country {
                id
                name
              }
              image
            }
            students {
              id
              clinic {
                id
                schoolName
              }
              firstname
              lastname
              parentFirstname
              parentLastname
              parentNickname
              email
              gender
              dob
              zipCode
              currentAddress
              mobileno
              image
              country {
                id
                name
              }
              learnerassessmentsSet {
                edges {
                  node {
                    id
                  }
                }
              }
            }
            username
            firstName
            lastName
            email
            school {
              id
              schoolName
              email
              address
              contactNo
              logo
              isDefault
              studentsSet {
                edges {
                  node {
                    id
                    firstname
                    lastname
                    mobileno
                    currentAddress
                    zipCode
                    email
                    gender
                    dob
                  }
                }
              }
              clinictutorialvideosSet {
                edges {
                  node {
                    id
                    clinic {
                      id
                      schoolName
                    }
                    tutorialVideos {
                      id
                      video {
                        id
                        videoName
                        videoUrl
                      }
                      isActive
                      module {
                        id
                        name
                        isActive
                      }
                      subModule {
                        id
                        name
                        isActive
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(data => {
      localStorage.setItem('userId', JSON.stringify(data?.data.userDetails.id))

      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function logout() {
  return client.cache.reset()
}

export async function RefreshToken() {
  if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
    return false
  }

  return client
    .mutate({
      mutation: gql`
        mutation RefreshToken($token: String) {
          refreshToken(input: { token: $token }) {
            token
            payload
          }
        }
      `,
      variables: {
        token: JSON.parse(localStorage.getItem('token')),
      },
    })
    .then(data => {
      localStorage.setItem('token', JSON.stringify(data?.data.refreshToken.token))

      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function accountDeleteRequest(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation {
          accountDeleteRequest(input: {}) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function registerUserOnFirebase(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation registerUserOnFirebase($fcmToken: String!, $deviceId: String!) {
          registerUserOnFirebase(
            input: { registrationToken: $fcmToken, deviceType: "web", deviceId: $deviceId }
          ) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function removeUserToken(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation removeFirebaseToken($deviceId: String!) {
          removeFirebaseToken(input: { deviceType: "web", deviceId: $deviceId }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getAllNotifications() {
  return client
    .query({
      query: gql`
        query {
          getNotifications(last: 20) {
            edges {
              node {
                id
                notifyType
                recipient {
                  id
                  username
                }
                sender {
                  id
                  firstName
                  lastName
                  isStaff
                  staff {
                    id
                    firstname
                    lastname
                    image
                  }
                  students {
                    id
                    image
                    firstname
                    lastname
                    parent {
                      id
                      firstName
                      lastName
                    }
                  }
                }
                title
                description
                timestamp
                isReminder
                read
                notifyType
                actorObjectId
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function markNotificationRead(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation markNotifcationRead($notificationId: ID!) {
          markNotifcationRead(input: { pk: $notificationId }) {
            status
            message
            details {
              id
              notifyType
              recipient {
                id
                username
              }
              sender {
                id
                firstName
                lastName
                isStaff
                staff {
                  id
                  image
                }
                students {
                  id
                  image
                }
              }
              title
              description
              timestamp
              read
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function markAllRead() {
  return client
    .mutate({
      mutation: gql`
        mutation {
          markAllNotifcationAsRead(input: {}) {
            status
            message
            details {
              id
              notifyType
              actorObjectId
            }
          }
        }
      `,
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function sendOtpForAccountDelete(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation sendOtpForDeactivation($deleteId: String!) {
          sendOtpForDeactivation(input: { userUid: $deleteId }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function verifyOtpForAccountDelete(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation verifyOtpForDeactivation($deleteId: String!, $otp: String!) {
          verifyOtpForDeactivation(input: { userUid: $deleteId, otp: $otp }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}
